import React from "react";
import "antd/dist/antd.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
// modules import
import SearchStudentData from "./Pages/Student/SearchStudentData";
import Login from "./Pages/Auth/Login";
import SchoolList from "./Pages/School/SchoolList";
import ListBatch from "./Pages/Batch/ListBatch";
import ListEvaluation from "./Pages/Evaluation/ListEvaluation";
import AddEvaluationModules from "./Pages/Evaluation/AddEvaluationModules";
import ListStudent from "./Pages/Student/manage/ListStudents";
import AddSchoolForm from "./Pages/School/AddSchoolForm";
import Verify from "./Pages/Verify/Verify";
import BatchForm from "./Pages/Batch/BatchForm";
import PageNotFound from "./Pages/PageNotFound";
import DuplicateComponent from "./Pages/Batch/components/DuplicateComponent";
import ListMarksheet2 from "./Pages/marksheet/ListMarksheet2";
import ListCert from "./Pages/Certificate/listCert";
import io from "socket.io-client";
import CartiVerify from "./Pages/certificate_verify/VerifyCert";
import CertEmail from "./Pages/mail/cert_mail";
import MarksheetEmail from "./Pages/mail/marksheet_mail";

const URL = process.env.REACT_APP_API_URL;

const socket = io(URL);

const App = () => {
  // 857e4966a8f95b1603d61dc0122bca1b79d3354d this perfect working marksheet on this hashcode of git
  // try search this on chat gpt
  // while using socket with react react useeffect call socket api many time how to fix this issue 
  // https://chat.openai.com/c/b3023af4-4d1c-4f1e-bb95-166e15f7048d go on this chatgpt link for socket optimization
  //   import { useEffect, useRef } from 'react';
  // import io from 'socket.io-client';
  // function MyComponent() {
  //   const socketRef = useRef(null);

  //   useEffect(() => {
  //     if (!socketRef.current) {
  //       // create the socket instance if it doesn't exist
  //       socketRef.current = io('http://localhost:3000');
  //     }

  //     // emit events or listen to incoming events using the socket instance
  //     socketRef.current.emit('event', { data: 'Hello world!' });

  //     socketRef.current.on('event', (data) => {
  //       console.log(data);
  //     });

  //     // clean up function to close the socket connection when the component unmounts
  //     return () => {
  //       socketRef.current.disconnect();
  //     };
  //   }, []);

  //   // render the component
  //   return (
  //     <div>
  //       <h1>My Component</h1>
  //     </div>
  //   );
  // }

  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<SearchStudentData />} /> */}
        <Route path="/admin" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/school">
            <Route index element={<SchoolList />} />
            <Route path="add" element={<AddSchoolForm />} />
            <Route path="update/:id" element={<AddSchoolForm />} />
          </Route>
          <Route path="/batch">
            <Route index element={<ListBatch socket={socket} />} />
            <Route path="add" element={<BatchForm />} />
            <Route path="update/:id" element={<BatchForm />} />
            <Route path="duplicate" element={<DuplicateComponent />} />
          </Route>
          {/* ============= EVALUATION ROUTES =========== */}
          <Route path="/evaluation" element={<ListEvaluation />} />
          <Route path="/evaluation/:id" element={<AddEvaluationModules />} />
          {/* ============= EVALUATION ROUTES =========== */}
          <Route path="/students" element={<ListStudent />} />
          {/* ======================= MARKSHEETS ROUTES ====================== */}
          {/* <Route path="/marksheet" element={<ListMarksheet />} /> */}
          <Route path="/playground-marksheet" element={<ListMarksheet2 />} />
          {/* <Route path="/add-marksheet/:id" element={<AddMarksheet />} /> */}
          {/* <Route path="/add-marksheet-old/:id" element={<AddMarksheetOld />} /> */}
          <Route
            path="/send-certificate-email"
            element={<CertEmail socket={socket} />}
          />
          <Route
            path="/send-marksheet-email"
            element={<MarksheetEmail socket={socket} />}
          /> 
          {/* ======================= MARKSHEETS ROUTES ====================== */}
          {/* ======================= CERTIFICATE ROUTES ====================== */}
          <Route path="/certificates" element={<ListCert socket={socket} />} />

          {/* <Route path="/certificate-template">
              <Route index element={<ListCertificateTemplates />} />
              <Route path="add" element={<CerficiateTemplateForm />} />
              <Route path="update/:id" element={<CerficiateTemplateForm />} />
            </Route> */}

          {/* ======================= CERTIFICATE ROUTES ====================== */}
        </Route>
        <Route
          path="/certificate/:student_id"
          element={<CartiVerify socket={socket} />}
        />
        <Route path="/">
          <Route index element={<SearchStudentData />} />
          <Route path=":student_code" element={<Verify />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
