import axios from "axios";
import { getHeaders } from "../../action-creators/auth/auth";
import actions from "../../actions/school/school.action";
import { message } from "antd";

const {
  addSchool,
  addSchoolError,
  addSchoolRequest,
  getSchoolError,
  getSchoolRequest,
  getSchoolSuccess,
  deleteSchoolSuccess,
  getOneSchoolError,
  getOneSchoolRequest,
  getOneSchoolSuccess,
  updateSchoolRequest,
  updateSchoolSuccess,
  updateSchoolError,
} = actions;

const URL = process.env.REACT_APP_API_URL;

export const getSchool = () => async (dispatch) => {
  try {
    dispatch(getSchoolRequest());

    const route = `${URL}/api/school`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(route, { headers });

    // console.log("school", data.data);

    dispatch(getSchoolSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    if (msg) dispatch(getSchoolError(msg));
  }
};

export const getOneSchool = (id) => async (dispatch) => {
  try {
    dispatch(getOneSchoolRequest());

    console.log(id, "asdf");
    const route = `${URL}/api/school/${id}`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(route, { headers });

    // console.log("school", data.data);

    dispatch(getOneSchoolSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    if (msg) dispatch(getOneSchoolError(msg));
  }
};

export const updateSchool = (id, nData) => async (dispatch) => {
  try {
    dispatch(updateSchoolRequest());

    const headers = dispatch(getHeaders());

    const route = `${URL}/api/school/${id}`;

    const { data } = await axios.put(route, nData, { headers });

    console.log(data);

    message.success("School updated sucessfully");
    dispatch(updateSchoolSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    if (msg) dispatch(updateSchoolError(msg));
  }
};

export const addNewSchool = (nData) => async (dispatch) => {
  try {
    dispatch(addSchoolRequest());

    const headers = dispatch(getHeaders());

    const route = `${URL}/api/school`;

    const { data } = await axios.post(route, nData, { headers });

    console.log("data from api of add new school", data);
    if (data === "School Code Already Exist") {
      const msg = data;
      message.error(msg);
      if (msg) dispatch(addSchoolError(msg));
    } else {
      message.success("School added sucessfully");
      dispatch(addSchool());
    }
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    if (msg) dispatch(addSchoolError(msg));
  }
};

export const deleteSChool = (id) => async (dispatch) => {
  try {
    const headers = dispatch(getHeaders());

    const route = `${URL}/api/school/${id}`;

    const { data } = await axios.delete(route, { headers });

    console.log(data);
    message.success("Record delete sucessfully");
    dispatch(deleteSchoolSuccess(id));
  } catch (error) {
    const msg = error.response.data.message;
    const errorMessage = msg ? msg : error.message;
    console.log(msg);
    message.error(errorMessage);
  }
};
