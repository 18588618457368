import { actions } from "../../actions/batch/batch.action";

const initialState = {
  data: [],
  loading: false,
  error: null,
  generate_format_loading: false,
  generate_format_file: null,
  batch_data: null,
};

const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_BATCH_REQUEST:
      return {
        ...state,
        loading: true,
        generate_format_loading: false,
        generate_format_file: null,
        isInserted: false,
        update_success: false,
        batch_data: null,
        bulk_gen_loading: false,
        bluk_gen_success: false,
      };

    case actions.BULK_BATCH_MARKSHEET_GEN_REQUEST:
      return {
        ...state,
        bulk_gen_loading: true,
      };

    case actions.BULK_BATCH_MARKSHEET_GEN_SUCCESS:
      return {
        ...state,
        bulk_gen_loading: false,
        bluk_gen_success: true,
      };

    case actions.BULK_BATCH_MARKSHEET_GEN_FAILED:
      return {
        ...state,
        bulk_gen_loading: false,
        bluk_gen_success: false,
      };

    case actions.GET_BATCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case actions.GET_BATCH_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actions.ADD_BATCH_REQUEST:
      return {
        ...state,
        add_batch_loading: true,
      };

    case actions.GET_ONE_BATCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ONE_BATCH_SUCCESS:
      return {
        ...state,
        batch_data: action.payload,
        loading: false,
      };
    case actions.GET_ONE_BATCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actions.ADD_BATCH_FAILED:
      return {
        ...state,
        add_batch_loading: false,
      };
    case actions.ADD_BATCH_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        add_batch_loading: false,
        isInserted: true,
      };

    case actions.UPDATE_BATCH_REQUEST:
      return {
        ...state,
        insertLoading: true,
      };
    case actions.UPDATE_BATCH_SUCCESS:
      return {
        ...state,
        update_success: true,
        insertLoading: false,
      };
    case actions.UPDATE_BATCH_FAILED:
      return {
        ...state,
        insertLoading: false,
      };
    case actions.DELETE_BATCH_SUCCESS:
      return {
        ...state,
        data: state.data.filter((x) => x.id !== action.payload),
      };

    case actions.GENERATE_FORMAT_LOADING:
      return {
        ...state,
        generate_format_loading: true,
      };

    case actions.GENERATE_FORMAT_SUCCESS:
      return {
        ...state,
        generate_format_loading: false,
        generate_format_file: action.payload,
      };

    case actions.GENERATE_FORMAT_ERROR:
      return {
        ...state,
        generate_format_loading: false,
      };
    default:
      return state;
  }
};

export { batchReducer };
