import { Button, Dropdown, Menu, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { GetMarksheets } from "../Redux/redux_toolkit/Marksheet";

const HeadTitle = ({
  title,
  setBatch,
  batch_data,
  batch,
  courseSelected,
  setCourseSelected,
  isCourseType,
  setIsCourseType,
  dispatch,
  isMail,
  socket,
}) => {
  useEffect(() => {
    // This effect will run only on the initial render
    const firstBatchWithCourse = batch_data.find(
      (data) => data.name === batch && data.evaluation_Master.length > 1
    );
    console.log("firstBatchWithCourse ", firstBatchWithCourse);

    if (firstBatchWithCourse) {
      setCourseSelected(firstBatchWithCourse.evaluation_Master[0].type);
      if (dispatch) {
        dispatch(GetMarksheets(batch, courseSelected));
      }
    }
    console.log("send get batch detail before if ",isMail, batch, courseSelected)
    
  }, [batch_data]);

  useEffect(() => {
    const hasCourseType = batch_data.some((data) => {
      if (data.name === batch && data.evaluation_Master.length > 1) {
        // dispatch(GetMarksheets(batch, courseSelected));
        return true;
      } else {
        // dispatch(GetMarksheets(batch));
        return false;
      }
    });
    setIsCourseType(hasCourseType);
  }, [batch_data, batch]);

  // useEffect(() => {

  // }, [courseSelected])

  const batchMenu = (
    <Menu>
      {batch_data.map((data) => (
        <Menu.Item key={data.id} onClick={() => setBatch(data.name)}>
          {data.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  console.log("batch 6789 ", batch_data);
  const courseMenu = (
    <Menu>
      {batch_data.length > 0 &&
        batch_data.map(
          (singleBatch) =>
            singleBatch.evaluation_Master &&
            singleBatch.evaluation_Master.map((evaluation) => {
              // console.log("evaluation ", singleBatch.name === batch && evaluation.length > 1, evaluation)
              if (
                singleBatch.name === batch &&
                singleBatch.evaluation_Master.length > 1
              ) {
                return (
                  <Menu.Item
                    key={evaluation.id}
                    onClick={() => setCourseSelected(evaluation.type)}
                  >
                    {evaluation.type}
                  </Menu.Item>
                );
              }
            })
        )}
    </Menu>
  );

  return (
    <div className="name">
      <Typography.Title style={{ wordBreak: "keep-all", margin: 0 }} level={3}>
        All&nbsp;{title}
      </Typography.Title>
      <Dropdown.Button
        style={{ float: "left", paddingLeft: "5px" }}
        className="dropdown-btn"
        overlay={batchMenu}
      >
        {(batch && batch) || "batch Name"}
      </Dropdown.Button>
      {isCourseType && (
        <Dropdown.Button
          style={{ float: "left", paddingLeft: "10px" }}
          className="dropdown-btn"
          overlay={courseMenu}
        >
          {courseSelected || "Course Type"}
        </Dropdown.Button>
      )}
    </div>
  );
};

export default HeadTitle;
