import { combineReducers } from "redux";
import { AuthReducer } from "./reducers/auth/auth.reducer";
import { SchoolReducer } from "./reducers/school/school.reducer";
import { batchReducer } from "./reducers/batch/batch.reducer";
import { evaluationReducer } from "./reducers/evaluation/evaluation.reducer";
import { StudentReducer } from "./reducers/students/student.reducer";
import { StudentMarksheetReducer } from "./reducers/marksheet/marksheet.reducer";
import AllMarksheet from "./redux_toolkit/Marksheet";
import AllCertificate from "./redux_toolkit/certificate/Certificate";
import { certificateReducer } from "./reducers/certificate/certificate.reducer";
import BulkMarksheetState from "./redux_toolkit/bulkMarksheetState";
import CertificateSSR from "./redux_toolkit/certificate/certificateSsr"

const rootReducer = combineReducers({
  auth: AuthReducer,
  batch: batchReducer,
  evaluation: evaluationReducer,
  school: SchoolReducer,
  student: StudentReducer,
  studentMarksheet: StudentMarksheetReducer,
  Marksheets: AllMarksheet,
  certificate: certificateReducer,
  AllCert: AllCertificate,
  BulkMarksheet: BulkMarksheetState,
  SSRCertificate: CertificateSSR
});

export { rootReducer };
