import { Button, Modal, Form, Input, message, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addbatch } from "../../Redux/action-creators/batch/batch";
import { addStudentMarksheet } from "../../Redux/action-creators/marksheet/marksheet";
import { GetMarksheets } from "../../Redux/redux_toolkit/Marksheet";

const AddStudentMarksheet = ({ open, handleCancel, handleOk, batch, courseSelected,  isCourseType}) => {
  const dispatch = useDispatch();
  const { data: batch_data } = useSelector((state) => state.batch);
  const { data: student_data } = useSelector((state) => state.student);
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    console.log("Values ", values);
    dispatch(addStudentMarksheet(values));
    let timeOut = setTimeout(() => {
      console.log("data56789 ", courseSelected, isCourseType)
      if (courseSelected !== "" && isCourseType){
        dispatch(GetMarksheets(batch, courseSelected));
      }else {
        dispatch(GetMarksheets(batch));
      }
    }, 1000);
    form.resetFields();
    handleCancel();
    return () => {
      clearTimeout(timeOut);
    };
  };

  const [data, setData] = useState([]);
  const [value, setValue] = useState("");

  const handleSearch = (newValue) => {
    console.log("value from student data is ", newValue, student_data);
    // if (newValue) {
    setData(
      student_data.filter((person) =>
        person.name.toLowerCase().includes(newValue.toLowerCase())
      )
    );
    // } else {
    //   setData(student_data);
    // }
    // console.log("after setting data ", data, student_data)
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const onSelect = (value) => {
    student_data.map((person) => {
      console.log("person data ", person, value);
      if (person.id === value) {
        setValue(person.name);
      }
    });
  };

  return (
    <Modal
      title="Add Student Marksheet"
      open={open}
      confirmLoading={false}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        layout={"vertical"}
        form={form}
        initialValues={{
          layout: "vertical",
          student_data: "",
          // remember: true,
        }}
        autoComplete="off"
        onFinish={handleFinish}
      >
        <Form.Item
          label="Student"
          name="student_id"
          rules={[
            {
              required: true,
              message: "Please select student",
            },
          ]}
        >
          <Select
            showSearch
            value={value}
            placeholder="Select Student"
            // style={props.style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onSelect={onSelect}
            notFoundContent={null}
            options={(data || student_data).map((x) => ({
              value: x.id,
              label: `${x.name} (${x.type})`,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Batch"
          name="batch_id"
          rules={[
            {
              required: true,
              message: "Please select batch",
            },
          ]}
        >
          <Select
            placeholder="Select batch"
            options={batch_data.map((x) => {
              return {
                label: `${x.name} (${x.type})`,
                value: x.id,
              };
            })}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddStudentMarksheet;
