const actions = {
  ADD_EVALUATION_MASTER_REQUEST: "ADD_EVALUATION_MASTER_REQUEST",
  ADD_EVALUATION_MASTER_SUCCESS: "ADD_EVALUATION_MASTER_SUCCESS",

  GET_EVALUATION_MASTER_REQUEST: "GET_EVALUATION_MASTER_REQUEST",
  GET_EVALUATION_MASTER_SUCCESS: "GET_EVALUATION_MASTER_SUCCESS",

  DELETE_EVALUATION_MASTER_REQUEST: "DELETE_EVALUATION_MASTER_REQUEST",
  DELETE_EVALUATION_MASTER_SUCCESS: "DELETE_EVALUATION_MASTER_SUCCESS",

  ADD_EVALUATION_MODULES_REQUEST: "ADD_EVALUATION_MODULES_REQUEST",
  ADD_EVALUATION_MODULES_SUCCESS: "ADD_EVALUATION_MODULES_SUCCESS",

  QUERY_EVALUATION_MODULES_REQUEST: "QUERY_EVALUATION_MODULES_REQUEST",
  QUERY_EVALUATION_MODULES_SUCCESS: "QUERY_EVALUATION_MODULES_SUCCESS",

  DELETE_EVALUATION_SUCCESS: "DELETE_EVALUATION_SUCCESS",

  deleteEvaluationSuccess: (id) => {
    return {
      type: actions.DELETE_EVALUATION_SUCCESS,
      payload: id
    };
  },

  getEvaluationRequest: () => {
    return {
      type: actions.GET_EVALUATION_MASTER_REQUEST,
    };
  },

  getEvaluationSucess: (payload) => {
    return {
      type: actions.GET_EVALUATION_MASTER_SUCCESS,
      payload,
    };
  },

  queryEvaluationRequest: () => {
    return {
      type: actions.QUERY_EVALUATION_MODULES_REQUEST,
    };
  },

  queryEvaluationSucess: (payload) => {
    console.log("payload", payload)
    return {
      type: actions.QUERY_EVALUATION_MODULES_SUCCESS,
      payload,
    };
  },

  addEvaluationRequest: () => {
    return {
      type: actions.ADD_EVALUATION_MASTER_REQUEST,
    };
  },

  addEvaluationSuccess: (payload) => {
    return {
      type: actions.ADD_EVALUATION_MASTER_SUCCESS,
      payload,
    };
  },

  addEvaluationModuleRequest: () => {
    return {
      type: actions.ADD_EVALUATION_MODULES_REQUEST,
    };
  },

  addEvaluationModuleSuccess: () => {
    return {
      type: actions.ADD_EVALUATION_MODULES_SUCCESS,
    };
  },
};

export { actions };
