import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { getHeaders } from "../../action-creators/auth/auth";

const URL = process.env.REACT_APP_API_URL;

let initialState = {
  item: [],
  error: "",
};

const GetCertificateScreen = createSlice({
  name: "SSR Certificate",
  initialState,
  reducers: {
    SSRCertificateSuccess(state, action) {
      state.item = action.payload;
      state.error = "";
    },
    SSRCertificateFailed(state, action) {
      state.error = action.payload;
      state.item = [];
    },
  },
});

export const verifyStudentCertificate = (student_code) => async (dispatch) => {
  try {

    const route = `${URL}/api/certificate/student/verify/${student_code}`;

    const { data } = await axios.get(route);

    dispatch(SSRCertificateSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    // message.error(msg);
    if (msg) dispatch(SSRCertificateFailed(msg));
  }
};

const { SSRCertificateSuccess, SSRCertificateFailed } =
  GetCertificateScreen.actions;
export default GetCertificateScreen.reducer;
