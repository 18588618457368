const actions = {
  CREATE_CERTIFICATE_BEGIN: "CREATE_CERTIFICATE_BEGIN",
  CREATE_CERTIFICATE_SUCCESS: "CREATE_CERTIFICATE_SUCCESS",
  CREATE_CERTIFICATE_FAILED: "CREATE_CERTIFICATE_FAILED",

  FETCH_CERTIFICATE_FAILED: "FETCH_CERTIFICATE_FAILED",
  FETCH_CERTIFICATE_SUCCESS: "FETCH_CERTIFICATE_SUCCESS",
  FETCH_CERTIFICATE_BEGIN: "FETCH_CERTIFICATE_BEGIN",

  FETCH_ONE_CERTIFICATE_FAILED: "FETCH_ONE_CERTIFICATE_FAILED",
  FETCH_ONE_CERTIFICATE_SUCCESS: "FETCH_ONE_CERTIFICATE_SUCCESS",
  FETCH_ONE_CERTIFICATE_BEGIN: "FETCH_ONE_CERTIFICATE_BEGIN",

  UPDATE_CERTIFICATE_BEGIN: "UPDATE_CERTIFICATE_BEGIN",
  UPDATE_CERTIFICATE_SUCCESS: "UPDATE_CERTIFICATE_SUCCESS",
  UPDATE_CERTIFICATE_FAILED: "UPDATE_CERTIFICATE_FAILED",

  DELETE_CERTIFICATE_BEGIN: "DELETE_CERTIFICATE_BEGIN",
  DELETE_CERTIFICATE_SUCCESS: "DELETE_CERTIFICATE_SUCCESS",
  DELETE_CERTIFICATE_FAILED: "DELETE_CERTIFICATE_FAILED",

  createCertificateBegin: () => {
    return {
      type: actions.CREATE_CERTIFICATE_BEGIN,
    };
  },

  createCertificateSuccess: (data) => {
    return {
      type: actions.CREATE_CERTIFICATE_SUCCESS,
      data,
    };
  },

  createCertificateFailed: (error) => {
    return {
      type: actions.CREATE_CERTIFICATE_FAILED,
      error,
    };
  },

  fetchCertificateBegin: () => {
    return {
      type: actions.FETCH_CERTIFICATE_BEGIN,
    };
  },

  fetchCertificateSuccess: (data) => {
    return {
      type: actions.FETCH_CERTIFICATE_SUCCESS,
      payload: data,
    };
  },

  fetchCertificateFailed: (error) => {
    return {
      type: actions.FETCH_CERTIFICATE_FAILED,
      error,
    };
  },

  fetchOneCertificateBegin: () => {
    return {
      type: actions.FETCH_ONE_CERTIFICATE_BEGIN,
    };
  },

  fetchOneCertificateSuccess: (data) => {
    return {
      type: actions.FETCH_ONE_CERTIFICATE_SUCCESS,
      payload: data,
    };
  },

  fetchOneCertificateFailed: (error) => {
    return {
      type: actions.FETCH_ONE_CERTIFICATE_FAILED,
      error,
    };
  },

  UPDATECertificateBegin: () => {
    return {
      type: actions.UPDATE_CERTIFICATE_BEGIN,
    };
  },

  UPDATECertificateSuccess: (data) => {
    return {
      type: actions.UPDATE_CERTIFICATE_SUCCESS,
    };
  },

  UPDATECertificateFailed: (error) => {
    return {
      type: actions.UPDATE_CERTIFICATE_FAILED,
      error,
    };
  },
};

export { actions };
