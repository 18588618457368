import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { getHeaders } from "../../action-creators/auth/auth";

const URL = process.env.REACT_APP_API_URL;


let initialState = {
  item: [],
  error: ""
}

const GetCertificateScreen = createSlice({
  name: "GetCertificate",
  initialState,
  reducers: {
    GetCertificateSuccess(state, action) {
      state.item = action.payload;
      state.error = ""
    },
    GetCertificateFailed(state, action) {
      state.error = action.payload;
      state.item = []
    }
  }
})

export const GetCertificate = (batch_name) => async (dispatch) => {
  console.log("api marksheet marksheets", batch_name)
  try {
    const reqRoute = `${URL}/api/certificate/certificates`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(reqRoute, { headers, params: { batch_name } });

    dispatch(GetCertificateSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
  }
}



const { GetCertificateSuccess, GetCertificateFailed } = GetCertificateScreen.actions;
export default GetCertificateScreen.reducer


