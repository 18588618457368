import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchStudentMarksheet, searchStudentMarksheetReset } from '../../Redux/action-creators/marksheet/marksheet';

const Navbar = () => {
  return (
     <div className="bg-[#F7F9FF] top-0 left-0 right-0 sticky mob:px-5 pc:px-[75px]" style={{
      position: "sticky",
      zIndex: "9999"
    }}>
      <div className="mx-2">
        <div className="flex items-center justify-between py-3">
          <img className="w-16 mob:m-auto" src="https://iide.co/wp-content/uploads/2022/11/IIDE.svg" />
        </div>
      </div>
   </div>
  )
}

export default Navbar;  