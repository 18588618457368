import React, { Fragment } from "react";

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Fragment>
      <section
        className="py-14 bg-[#222] text-center"
        style={{ marginTop: "68px" }}
      >
        <div className="text-xl text-white font-sf-ui-display-bold">
          © Copyright {year} IIDE
        </div>
      </section>
    </Fragment>
  );
};

export default Footer;
