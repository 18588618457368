import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormContainer from "../../Components/UI/FormContainer";
import Loading from "../../Components/UI/Loading";
import {
  addNewSchool,
  getOneSchool,
  updateSchool,
} from "../../Redux/action-creators/school/school";
import SidebarLayout from "../Layout/SidebarLayout";

const AddSchoolForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, insertLoading, isInserted, school_data, update_success } =
    useSelector((state) => state.school);

  useEffect(() => {
    if (isInserted || update_success) navigate("/school");
  }, [isInserted, navigate, update_success]);


  useEffect(() => {
    if (id) {
      dispatch(getOneSchool(id));
    }
  }, [dispatch, id]);

  const handleFinish = (values) => {
    if (!id) {
      dispatch(addNewSchool(values));
    } else if (id) {
      dispatch(updateSchool(id, values));
    }
  };

  console.log(school_data);
  return (
    <SidebarLayout active="school">
      {loading ? (
        <Loading />
      ) : (
        <FormContainer>
          <Form
            layout={"vertical"}
            form={form}
            initialValues={school_data && school_data}
            autoComplete="off"
            onFinish={handleFinish}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter school name!",
                },
              ]}
            >
              <Input placeholder="Enter school name..." />
            </Form.Item>
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please enter school code!",
                },
              ]}
            >
              <Input placeholder="Enter school code..." />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter school description!",
                },
              ]}
            >
              <Input.TextArea
                maxLength={100}
                style={{ height: 120 }}
                placeholder="Enter school description..."
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={insertLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </FormContainer>
      )}
    </SidebarLayout>
  );
};

export default AddSchoolForm;
