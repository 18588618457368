import React from "react";
import moment from "moment";
import "./CertBody.css";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import {
  XOutlined
} from "@ant-design/icons";

const CertBody = ({ verify_marksheet }) => {
  let isCert = window.location.href.split("//")[1].split("/")[1];
  let url = "";
  if (
    verify_marksheet && verify_marksheet.type.typeOfUrl === "certificate" &&
    verify_marksheet.type.typeOfBatch === "PG"
  ) {
    console.log("pg");
    url =
      "https://iide.co/master-mba-digital-marketing/?utm_source=pg_certificate&utm_medium=share_page&utm_campaign=iide&utm_term=pg_alumini&utm_content=iide_website_button&utm_team=website_team";
  } else if (
    verify_marksheet && verify_marksheet.type.typeOfUrl === "certificate" &&
    verify_marksheet.type.typeOfBatch === "UG"
  ) {
    url =
      "https://iide.co/online-digital-marketing-course/?utm_source=ug_certificate&utm_medium=share_page&utm_campaign=iide&utm_term=ug_alumini&utm_content=iide_website_button&utm_team=website_team";
  } 
  return (
    <div>
      <div className="app">
        <div className="detail">
          <div className="big-img">
            <img
              src={
                isCert !== "certificate"
                  ? verify_marksheet.image
                  : verify_marksheet.certificateImage
              }
              alt={
                isCert !== "certificate"
                  ? "Marksheet Image"
                  : "Certificate Image"
              }
            />
          </div>
          <div className="box">
            <div className="row">
              <div>
                <h2 className="mob:text-xl mod:font-medium pc:text-3xl pc:font-bold cert">
                  This {isCert !== "certificate" ? "marksheet" : "certificate"}{" "}
                  is verified by <br /> IIDE - The Digital School
                  <img className="lottie" src="/verify.gif" />
                </h2>
              </div>

              <div className="name flex !flex-col">
                <div className="flex gap-2 align-baseline font-semibold">
                  <span>Name: </span>
                  <p
                    style={{
                      fontFamily: "sf-ui-display-medium",
                      fontWeight: 400,
                      fontSize: 16,
                      margin: 0,
                    }}
                  >
                    {verify_marksheet.student.name}
                  </p>
                </div>
                <div className="flex gap-2 align-baseline font-semibold">
                  <span>Student ID: </span>
                  <p
                    style={{
                      fontFamily: "sf-ui-display-medium",
                      fontWeight: 400,
                      fontSize: 16,
                      margin: 0,
                    }}
                  >
                    {verify_marksheet.student.student_code}
                  </p>
                </div>
                {
                  verify_marksheet && verify_marksheet.type.typeOfUrl === "certificate" &&
                  verify_marksheet.type.typeOfBatch !== "UG" ? (
                    <div className="flex gap-2 align-baseline font-semibold">
                  <span>Issue Date: </span>
                  <p
                    style={{
                      fontFamily: "sf-ui-display-medium",
                      fontWeight: 400,
                      fontSize: 16,
                      margin: 0,
                    }}
                  >
                    {/* DateFormatter.formatDate(new Date(), "Do MMMM, YYYY") */}
                    {moment(verify_marksheet.batch.convocation_date).format(
                      "Do MMMM, YYYY"
                    )}
                  </p>
                </div>
                  ) : null
                }
              </div>
            </div>

            <div className="social-link">
              <a target="_blank" href={verify_marksheet.linkedInUrl}>
                <button className="linked-link">
                  Add to
                  <img
                    // src={`https://verified.sertifier.com/static/media/linkedin-logo-white.f17b6597.svg`}
                    src={`https://res.cloudinary.com/ddizpfhtk/image/upload/v1683807042/linkedin_1_a0dnxo.png`}
                    style={{
                      width: "71px",
                      paddingBottom: "4px",
                    }}
                  />
                </button>
              </a>
              {/* <a href={verify_marksheet.certificatePdf} target="_blank"> */}
              <button
                onClick={() =>
                  window.open(
                    isCert !== "certificate"
                      ? verify_marksheet.pdf
                      : verify_marksheet.certificatePdf
                  )
                }
                className="download-btn"
              >
                Download PDF
                <ArrowDownTrayIcon className="h-5 w-5" />
              </button>
              {/* </a> */}
            </div>

            <div className="your-social-link">
              <h1 className="mx-auto text-lg text-sm text-md font-extrabold text-black mt-5 mb-5 font-sf-ui-display-medium">
                Share on Social
              </h1>
              <div className="flex flex-row gap-8 pb-6">
                <a
                  className="rounded-md bg-[#039be5] pt-1"
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${
                    window.location.href
                  }&title=${
                    verify_marksheet && verify_marksheet.batch.program
                  } | IIDE - The Digital School`}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <i
                    className="fa-brands fa-linkedin-in text-white  mx-1 p-1"
                    style={{ fontSize: "20px" }}
                  ></i>
                </a>
                <a
                  className="rounded-md bg-[#039be5] pt-1"
                  href={`https://twitter.com/share?url=Hey!%0A%0AI'm%20happy%20to%20share%20with%20you%20that%20I've%20received%2Fsecured%20Grade%20${
                    verify_marksheet && verify_marksheet.grade
                  }%20in%20@iideonline's%20 ${
                    verify_marksheet && verify_marksheet.batch.program
                  }.%20%0A%0AThis%20has%20taught%20me%20to%20be%20diligent%20and%20go%20for%20what%20I%20want.%20Super%20grateful%20to%20see%20my%20results.%20%0A%0ACheck%20this%20out!%20${
                    window.location.href
                  }`}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <XOutlined className="mx-1 p-1 !text-[#ffffff]" style={{ fontSize: "20px" }} />
                </a>
                <a
                  className="rounded-md bg-[#039be5]"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}%2F&amp;src=sdkpreparse`}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <i
                    className="fa-brands fa-facebook-f mx-1 px-1 text-white"
                    style={{ fontSize: "20px", padding: "6px 6px 1px 6px" }}
                  ></i>
                </a>
                <a
                  className="rounded-md bg-[#039be5]"
                  href={`https://api.whatsapp.com/send?text=Hey!%0A%0AI'm%20happy%20to%20share%20with%20you%20that%20I've%20received%2Fsecured%20Grade%20${
                    verify_marksheet && verify_marksheet.grade
                  }%20in%20IIDE's%20 ${
                    verify_marksheet && verify_marksheet.batch.program
                  }.%20%0A%0AThis%20has%20taught%20me%20to%20be%20diligent%20and%20go%20for%20what%20I%20want.%20Super%20grateful%20to%20see%20my%20results.%20%0A%0ACheck%20this%20out!%20${
                    window.location.href
                  }`}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <i
                    className="fa-brands fa-whatsapp mx-1 p-1 text-white"
                    style={{ fontSize: "20px", paddingTop: "6px" }}
                  ></i>
                </a>
              </div>
            </div>
            {/* <div className="iide-social-section">
            <div className="iide-img bg-white">
              <img
                className="mob:w-20 mob:mx-auto mob:mt-11 pc:w-15 pc:h-12 pc:mt-0 pc:ml-0 bor"
                src="https://res.cloudinary.com/ddizpfhtk/image/upload/v1668682125/img/IIDE-Logo_xmqq0z.png"
              />
            </div>
            <div className="iide-social-link">
              <div className="title">IIDE - Digital School</div>
              <button className="site-btn">IIDE'S Website</button>
              <div className="flex flex-row gap-8 pb-6 mt-4">
                <a
                  className="rounded-md bg-white pt-1 border-solid border-2 border-sky-300"
                  href={"https://in.linkedin.com/school/the-digital-school/"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <i
                    className="fa-brands fa-linkedin-in text-[#039be5] mx-1 p-1"
                    style={{ fontSize: "20px" }}
                  ></i>
                </a>
                <a
                  className="rounded-md bg-white pt-1 border-solid border-2 border-sky-300 "
                  href={"https://twitter.com/iideonline?lang=en"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <i
                    className="fa-brands fa-twitter mx-1 p-1 text-[#039be5]"
                    style={{ fontSize: "20px" }}
                  ></i>
                </a>
                <a
                  className="rounded-md bg-white border-solid border-2 border-sky-300"
                  href={`https://www.facebook.com/IndianInstituteOfDigitalEducation/`}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <i
                    className="fa-brands fa-facebook-f mx-1 px-1 text-[#039be5]"
                    style={{ fontSize: "20px", padding: "6px 6px 1px 6px" }}
                  ></i>
                </a>
              </div>
            </div>
            
          </div> */}
          </div>
        </div>
      </div>
      {isCert === "certificate" ? (
        <section className="flex mob:flex-col-reverse pc:flex-row mob:p-10 pc:px-[70px] pt-[70px] gap-12">
          <div className="pc:basis-1/4">
            <div
              className="flex flex-col text-center mob:py-2 mob:px-3 pc:py-9 bg-[#f7f9ff] rounded-md"
              style={{
                boxShadow:
                  "0 3px 6px rgb(9 30 66 / 3%), 0 2px 4px rgb(9 30 66 / 8%)",
              }}
            >
              {/* <h1 className="text-center font-medium text-lg mt-5 mb-0 font-sf-ui-display-medium">
                This credential has been issued by
              </h1> */}
              <div className="w-36 h-36 m-auto mob:my-5 pc:my-10 bg-white shadow-lg shadow-gray-200 rounded-md">
                <img
                  className="mob:w-20 mob:mx-auto mob:mt-11 pc:w-16 pc:h-12 pc:mt-11 pc:ml-10"
                  src="https://res.cloudinary.com/ddizpfhtk/image/upload/v1668682125/img/IIDE-Logo_xmqq0z.png"
                />
              </div>
              <div className="font-extrabold text-center text-2xl font-sf-ui-display-medium mob:mt-5">
                IIDE - The Digital School
              </div>
              <div className="text-center pt-5">
                <a
                  className="bg-[#039BE5] px-16 py-3 rounded-md text-white text-lg font-sf-ui-display-medium hover:text-white"
                  href={url}
                  rel="dofollow"
                  target={"_blank"}
                >
                  IIDE's website
                </a>
              </div>
              <div className="pt-5 text-center text-black underline font-bold font-sf-ui-display-medium">
                <a
                  href="mailto:support@iide.co"
                  rel="dofollow"
                  className="text-black"
                >
                  Contact IIDE
                </a>
              </div>
              <div className="flex flex-row justify-evenly mob:py-8 pc:py-10 text-[#222]">
                <a
                  rel="dofollow"
                  className="rounded-md bg-[#039be5] text-white pt-1 hover:text-white"
                  href="https://www.linkedin.com/school/indian-institution-of-digital-education/"
                  target={"_blank"}
                >
                  <i
                    className="fa-brands fa-linkedin-in mx-1 p-1"
                    style={{ fontSize: "20px" }}
                  ></i>
                </a>
                <a
                  rel="dofollow"
                  className="rounded-md bg-[#039be5] pt-1 hover:text-white"
                  target={"_blank"}
                  href="https://twitter.com/IIDEonline"
                >
                  <XOutlined className="mx-1 p-1 !text-[#fdfdfd]" style={{ fontSize: "20px" }} />
                </a>
                <a
                  rel="dofollow"
                  className="rounded-md bg-[#039be5] hover:text-white"
                  target={"_blank"}
                  href="https://www.facebook.com/IndianInstituteOfDigitalEducation/"
                >
                  <i
                    className="fa-brands fa-facebook-f px-3 py-2 text-white"
                    style={{ fontSize: "20px" }}
                  ></i>
                </a>
                <a
                  rel="dofollow"
                  className="rounded-md bg-[#039be5] pt-1 hover:text-white"
                  href="https://www.instagram.com/iideonline"
                  target={"_blank"}
                >
                  <i
                    className="fa-brands fa-instagram mx-1 p-1 text-white"
                    style={{ fontSize: "20px" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>

          <div className="pc:basis-3/4 ">
            <div>
              <h1 className="mob:text-xl pc:text-3xl font-bold mb-0 text-[#222] font-sf-ui-display-bold">
                {verify_marksheet && verify_marksheet.batch.program}
              </h1>
            </div>
            <div className="flex flex-wrap">
              {/* <div className="mob:basis-1/2 pc:basis-1/3 mt-5">
              <div className="text-lg font-sf-ui-display-bold">Issued on</div>
              <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                {verify_marksheet &&
                  moment(verify_marksheet.updatedAt).format("ll")}
              </div>
            </div> */}
              {/* <div className="mob:basis-1/2 pc:basis-1/3 mt-5">
                <div className="text-lg font-sf-ui-display-bold">Grade</div>
                <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                  {verify_marksheet && verify_marksheet.grade !== null ? `${verify_marksheet.grade}`: ""}
                </div>
              </div> */}
              <div className="mob:basis-1/2 pc:basis-1/3 mt-5">
                <div className="text-lg font-sf-ui-display-bold">
                  Batch Title
                </div>
                <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                  {verify_marksheet && verify_marksheet.batch.name}
                </div>
              </div>
              <div className="mob:basis-1/2 pc:basis-1/3 mt-5">
                <div className="text-lg font-sf-ui-display-bold">Duration</div>
                <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                  {verify_marksheet && verify_marksheet.batch.duration}
                </div>
              </div>
              {/* <div className="mob:basis-1/2 pc:basis-1/3 mt-5">
              <div className="text-lg font-sf-ui-display-bold">Attendance</div>
              <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                {verify_marksheet && `${verify_marksheet.attendance}%`}
              </div>
            </div>
            <div className="mob:basis-1/2 pc:basis-1/3 mt-5">
              <div className="text-lg font-sf-ui-display-bold">Percentage</div>
              <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                {verify_marksheet && `${verify_marksheet.percentage}%`}
              </div>
            </div> */}
            </div>
            <div className="pt-5">
              {/* <h1 className="mob:text-xl pc:text-xl font-bold mb-0 text-[#222] font-sf-ui-display-bold">
                Grading System
              </h1>
              <ul className="list-disc pt-2 font-sf-ui-display-medium text-sm mob:ml-3 ml-9">
                <li className="mb-2">
                  IIDE follows the Absolute Grading System while calculating the
                  final grade
                </li>
                <li className="mb-2">
                  Absolute Grading is a standardised evaluation of varying
                  levels of achievement. A letter grade is assigned to fixed
                  percentage scales. Percentage scores are derived as follows
                </li>
                <li className="mb-2">
                  The final modular and Capstone Project scores have two
                  criteria: the assignment and exam scores. While calculating
                  the final score, we give 70% weightage to the assignment score
                  and 30% weightage to the exam score. The formula then is =
                  (Assignment Score*70/100) + (Exam Score*30/100)
                </li>
                <li className="mb-0">
                  For the overall score, the modular score and the Capstone
                  Project score are given equal weightage. Therefore, the
                  formula is: 50% of Modular Scores + 50% of the Capstone
                  Project Score
                </li>
              </ul> */}
              <p className="mob:text-xl pc:text-lg mb-0 text-[#222] font-sf-ui-display-bold">
                Notes:
              </p>
              <div>
                {verify_marksheet &&
                verify_marksheet.batch &&
                verify_marksheet.batch.type === "KG" ? (
                  <ol className="list-disc py-2 font-sf-ui-display-medium text-sm mob:ml-3 ml-9">
                    <li className="mb-1">
                      These grades are for Advanced Digital Marketing Course
                      from IIDE in association with KGISL Micro College, a
                      2-month Instructor-Led-Offline-Live program{" "}
                    </li>
                    <li className="mb-1">
                      Performance Marketing includes Organic & Paid Search
                      Marketing along with Analytics. Creative Strategy includes
                      of Content Marketing & Strategy as well as Social Media
                      Marketing.
                    </li>
                    <li className="mb-1">
                      The Capstone Project is a comprehensive test and serves as
                      a full-course assessment.
                    </li>
                    <li className="mb-1">
                      Grades are calculated basis predetermined cutoff levels
                      and standards of performance as decided by IIDE and thus,
                      should not be compared to any other institute.
                    </li>
                  </ol>
                ) : verify_marksheet &&
                  verify_marksheet.batch &&
                  verify_marksheet.batch.type === "UG" ? (
                  <ol className="list-disc py-2 font-sf-ui-display-medium text-sm mob:ml-3 ml-9">
                    <li className="mb-1">
                      The {verify_marksheet.batch.program}, a{" "}
                      {verify_marksheet.batch.duration} hybrid program, combines
                      online instruction with practical assignments and in-class
                      projects to teach students digital marketing concepts
                      ranging from basic to advanced levels.
                    </li>
                    <li className="mb-1">
                      Students explore modules covering performance marketing,
                      which includes both organic and paid search marketing, as
                      well as analytics. The creative strategy component
                      consists of content marketing and strategy, in addition to
                      social media marketing.
                    </li>
                    <li className="mb-1">
                      The capstone project serves as a comprehensive evaluation,
                      assessing the full scope of the course.
                    </li>
                    {/* <li>
                      Grades are calculated basis predetermined cutoff levels
                      and standards of performance as decided by IIDE and thus,
                      should not be compared to any other institute
                    </li> */}
                  </ol>
                ) : (
                  <ol className="list-disc py-2 font-sf-ui-display-medium text-sm mob:ml-3 ml-9">
                    <li className="mb-1">
                      The {verify_marksheet.batch.program}, an{" "}
                      {verify_marksheet.batch.duration} MBA-level PG Program, is
                      an offline course offering two distinct <br />
                      specializations: Performance Marketing and Creative
                      Marketing. This program aims to equip students with
                      managerial skills, and upon completion, students are
                      guaranteed 100% placement, including a mandatory two-month
                      internship.
                    </li>
                    <li className="mb-1">
                      At the beginning of the program, students learn modules
                      such as Website Planning & Development, Search Marketing
                      (Ads & Organic), Brand Strategy, Social Media (Paid &
                      Organic), Online Reputation Management, Google Analytics,
                      E-Commerce, Email Marketing, and Media Planning.
                    </li>
                    <li className="mb-1">
                      Upon completion of these foundational modules, students
                      choose between Creative and Performance Marketing
                      specializations. Creative Marketing focuses on video
                      marketing and scriptwriting, podcast promotion, influencer
                      collaboration, persuasive copywriting, and Adobe
                      Photoshop. Performance Marketing delves into Customer
                      Relationship Management, Programmatic Ads and Conversion
                      Rate Optimization.
                    </li>
                    {/* <li>
                      Grades are calculated basis predetermined cutoff levels
                      and standards of performance as decided by IIDE and thus,
                      should not be compared to any other institute
                    </li> */}
                  </ol>
                )}
              </div>
              {/* <table
                className="text-center w-full overflow-hidden rounded-md"
                style={{
                  boxShadow:
                    "0 3px 6px rgb(9 30 66 / 3%), 0 2px 4px rgb(9 30 66 / 8%)",
                }}
              >
                <tr className="bg-[#039be5] font-sf-ui-display-bold text-xl text-white">
                  <td className="py-1 border-r-2" style={{ width: "12%" }}>
                    O
                  </td>
                  <td className="border-r-2" style={{ width: "12%" }}>
                    A
                  </td>
                  <td className="border-r-2" style={{ width: "12%" }}>
                    B
                  </td>
                  <td className="border-r-2" style={{ width: "12%" }}>
                    C
                  </td>
                  <td className="border-r-2" style={{ width: "12%" }}>
                    D
                  </td>
                  <td style={{ width: "12%" }}>F</td>
                </tr>
                <tr className="font-sf-ui-display-medium bg-[#F7F9FF] mob:text-l pc:text-xl">
                  <td className="py-1 border-r-1" style={{ width: "12%" }}>
                    92%+
                  </td>
                  <td className="border-r-1" style={{ width: "12%" }}>
                    75-92%
                  </td>
                  <td className="border-r-1" style={{ width: "12%" }}>
                    60-75%
                  </td>
                  <td className="border-r-1" style={{ width: "12%" }}>
                    50-60%
                  </td>
                  <td className="border-r-1" style={{ width: "12%" }}>
                    35-50%
                  </td>
                  <td style={{ width: "12%" }}>{" > 35%"}</td>
                </tr>
              </table> */}
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default CertBody;
