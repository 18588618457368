import React, { useEffect, useRef, useState } from "react";
import {
  SearchOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
  FileZipOutlined,
  CopyOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Tooltip,
  Spin,
  Alert,
  notification,
} from "antd";
import Highlighter from "react-highlight-words";
import SidebarLayout from "../Layout/SidebarLayout";
import AddBatch from "./AddBatch";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSchool } from "../../Redux/action-creators/school/school";
import {
  bluKMarksheetGen,
  deleteBatch,
  downloadFileZip,
  generateCSV,
  generateFormat,
  getBatch,
} from "../../Redux/action-creators/batch/batch";
import { GetBulkMarksheetState } from "../../Redux/redux_toolkit/bulkMarksheetState";

const ListBatch = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [progress, setProgress] = useState({});
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement) => {
    api.info({
      message: `Bulk Marksheet Started`,
      description: "Bulk Marksheet Generate Started",
      placement,
    });
  };

  let { loading, data, bluk_gen_success, bulk_gen_loading } = useSelector(
    (state) => state.batch
  );
  const { data: school_data } = useSelector((state) => state.school);

  useEffect(() => {
    socket.on("bulk_progress_marksheet", (data) => {
      setProgress(data);
    });
    socket.on("bulk_progress_marksheet_done", () => {
      console.log("Running bulk marksheet progress");
      dispatch(GetBulkMarksheetState(true));
    });

    return () => {
      socket.removeAllListeners()
    }
  }, [socket]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [bulkId, setBulkId] = useState(null);

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    dispatch(getBatch());
    if (!school_data) {
      dispatch(getSchool());
    }
  }, [dispatch, school_data, bulk_gen_loading]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Program",
      dataIndex: "program",
      key: "program",
      width: "20%",
      ...getColumnSearchProps("program"),
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
      width: "20%",
      ...getColumnSearchProps("school"),
    },
    {
      title: "Batch Type",
      dataIndex: "type",
      key: "type",
      width: "20%",
      ...getColumnSearchProps("type"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const handleBulkMarksheetGen = (id) => {
    setBulkId(id);
    dispatch(bluKMarksheetGen(id));
  };

  if (data && data.length) {
    data = data.map((x) => {
      return {
        ...x,
        school: x.school ? x.school.name : "N/A",
        action: (
          <div className="table-actions" 
          >
            <Tooltip title="Duplicate Batch" placement="top">
              <CopyOutlined
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginRight: 10,
                  fontSize: 18,
                }}
                onClick={() => navigate("/batch/duplicate", { state: x })}
              />
            </Tooltip>
            <Tooltip title="Generate Marksheets" placement="top">
              {bulk_gen_loading && bulkId === x.id ? (
                <Spin
                  style={{
                    marginRight: 10,
                  }}
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 20,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <FilePdfOutlined
                  style={{
                    color: "green",
                    cursor: "pointer",
                    marginRight: 5,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    // setProgress({})
                    openNotification("topRight");
                    handleBulkMarksheetGen(x.id);
                  }}
                />
              )}
            </Tooltip>
            <Tooltip title="Download All Marksheets" placement="top">
              <FileZipOutlined
                style={{
                  color: "green",
                  cursor: "pointer",
                  marginRight: 5,
                  fontSize: 18,
                }}
                onClick={() => dispatch(downloadFileZip(x.id))}
              />
            </Tooltip>
            {/* <Tooltip title="Download Format" placement="top">
              <DownloadOutlined
                style={{
                  color: "green",
                  cursor: "pointer",
                  marginRight: 5,
                  fontSize: 18,
                }}
                onClick={() => dispatch(generateFormat(x.id))}
              />
            </Tooltip> */}

            <Tooltip title="Download CSV" placement="top">
              <ArrowDownOutlined
                style={{
                  color: "green",
                  cursor: "pointer",
                  marginRight: 10,
                  fontSize: 18,
                }}
                onClick={() => dispatch(generateCSV(x.id, x.name))}
              />
            </Tooltip>

            <Tooltip title="Edit" placement="top" 
            
            >
              <EditFilled
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginRight: 5,
                  fontSize: 18,
                  paddingTop: "0px"

                }}
                onClick={() => navigate(`/batch/update/${x.id}`)}
              />
            </Tooltip>

            <Tooltip title="Delete" placement="top" >
              <DeleteFilled
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: 5,
                  fontSize: 18,
                    paddingTop: "0px"
                }}
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure delete this batch?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk() {
                      console.log("OK", x.id);
                      dispatch(deleteBatch(x.id));
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });
  }

  const [open, setOpen] = useState(false);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  return (
    <SidebarLayout active="batch">
      {contextHolder}
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        title={() => (
          <Row>
            <Typography.Title level={3} style={{ margin: 0 }}>
              All Batches
            </Typography.Title>
            {Object.keys(progress).length > 0 ? (
              <Space
                style={{
                  paddingLeft: "10px",
                }}
              >
                <Typography>
                  ({progress.progress} / {progress.outof})
                </Typography>
              </Space>
            ) : null}
            <Button
              type="primary"
              style={{ marginLeft: "auto" }}
              onClick={() => navigate("/batch/add")}
            >
              Add Batch
            </Button>
          </Row>
        )}
        pagination={{
          defaultPageSize: 10,
          position: ["bottomCenter"],
          total: data ? data.length : 0,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
      <AddBatch open={open} handleCancel={handleCancel} handleOk={handleOk} />
    </SidebarLayout>
  );
};

export default ListBatch;
