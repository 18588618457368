import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = () => {
  const { data } = useSelector(state => state.auth)
  return data ? <Outlet /> : <Navigate to="/admin" />;
};

export default ProtectedRoute;
