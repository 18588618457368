import actions from "../../actions/school/school.action";

const {
  ADD_SCHOOL_FAILED,
  ADD_SCHOOL_SUCCESS,
  GET_SCHOOL_FAILED,
  GET_SCHOOL_REQUEST,
  GET_SCHOOL_SUCCESS,
  ADD_SCHOOL_REQUEST,
  RESET_ADD_SCHOOL_SUCCESS,
  DELETE_SCHOOL_SUCCESS,
  GET_ONE_SCHOOL_FAILED,
  GET_ONE_SCHOOL_REQUEST,
  GET_ONE_SCHOOL_SUCCESS,
  UPDATE_SCHOOL_REQUEST,
  UPDATE_SCHOOL_SUCCESS,
  UPDATE_SCHOOL_FAILED,
} = actions;

const initialState = {
  data: null,
  loading: false,
  error: null,
  school_data: null,
};

const SchoolReducer = (state = initialState, action) => {
  const { type, data, error, deletedId } = action;
  switch (type) {
    case GET_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true,
        insertLoading: false,
        isInserted: false,
        school_data: null,
        update_success: false,
      };
    case GET_SCHOOL_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_SCHOOL_FAILED:
      return {
        ...state,
        error,
        insertLoading:false,
        loading: false,
      };
    case GET_ONE_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ONE_SCHOOL_SUCCESS:
      return {
        ...state,
        school_data: action.payload,
        loading: false,
      };
    case GET_ONE_SCHOOL_FAILED:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_SCHOOL_REQUEST:
      return {
        ...state,
        insertLoading: true,
      };
    case UPDATE_SCHOOL_SUCCESS:
      return {
        ...state,
        update_success: true,
        insertLoading: false,
      };
    case UPDATE_SCHOOL_FAILED:
      return {
        ...state,
        error,
        insertLoading: false,
      };
    case ADD_SCHOOL_REQUEST:
      return {
        ...state,
        insertLoading: true,
        isInserted: false,
      };
    case ADD_SCHOOL_SUCCESS:
      return {
        ...state,
        isInserted: true,
      };
    case DELETE_SCHOOL_SUCCESS:
      return {
        ...state,
        data: state.data.filter((x) => x.id !== deletedId),
      };
    case RESET_ADD_SCHOOL_SUCCESS:
      return {
        ...state,
        isInserted: false,
      };
    case ADD_SCHOOL_FAILED:
      return {
        ...state,
        insertLoading: false,
        error,
      };
    default:
      return state;
  }
};

export { SchoolReducer };
