import React, { useEffect, useRef, useState } from "react";
import {
  SearchOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  DownloadOutlined,
  EyeFilled,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import {
  Button,
  Input,
  Space,
  Table,
  Typography,
  Modal,
  Tooltip,
  Upload,
  message,
  InputNumber,
  Form,
  Popconfirm
} from "antd";
import Highlighter from "react-highlight-words";
import SidebarLayout from "../Layout/SidebarLayout";
import AddStudentMarksheet from "./AddStudentMarksheet";
import { useDispatch, useSelector } from "react-redux";
import { getSchool } from "../../Redux/action-creators/school/school";
import { generateFormat, getBatch } from "../../Redux/action-creators/batch/batch";
import { getStudents } from "../../Redux/action-creators/students/students";
import {
  deleteStudentMarksheet,
  updateAttendance,
  updateModuleObtainedMarks,
} from "../../Redux/action-creators/marksheet/marksheet";
import { GetMarksheets } from "../../Redux/redux_toolkit/Marksheet";
import "./styles.css";
import HeadTitle from "../../Components/title";

const ListMarksheet2 = () => {
  // let { loading, data } = useSelector((state) => state.studentMarksheet);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [batch, setBatch] = useState("");
  const [courseSelected, setCourseSelected] = useState("");
  const [isCourseType, setIsCourseType] = useState(false);

  const dispatch = useDispatch();

  let { item } = useSelector((state) => state.Marksheets);
  const { data: batch_data } = useSelector((state) => state.batch);

  let [data, setData] = useState([]);

  const isEditing = (record) => {
    if (record) {
      return record.id === editingKey;
    }
  };

  useEffect(() => {
    dispatch(getBatch());
    dispatch(getSchool());
    dispatch(getStudents());
    setBatch(batch_data.length > 0 ? batch_data[0].name : "");
    // dispatch(GetMarksheets(batch));
    setData(item && item);
  }, [dispatch, batch_data.length]);

  useEffect(() => {
    if (courseSelected !== "" && isCourseType) {
      dispatch(GetMarksheets(batch, courseSelected));
    } else if (!isCourseType) {
      dispatch(GetMarksheets(batch));
    }
  }, [batch, isCourseType, courseSelected]);

  // useEffect(() => {
  //   console.log("batch 303 ", batch)
  //   dispatch(GetMarksheets(batch));
  // }, [batch]);

  // useEffect(() => {

  // }, [batch]);

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  useEffect(() => {
    if (item && item.length > 0) {
      setData([...item]);
    } else {
      setData([]);
    }
  }, [item]);

  const uniqueObject = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();

      let updateModified = [];

      let isAttendance = false;
      Object.keys(row).map((data) => {
        if (form.isFieldTouched(data)) {
          let split = data.split("_");
          let mod_name = split.slice(4).join(" ");
          if (split[0] === "assignment") {
            updateModified.push({
              assignment_module_obtained_marks: row[data],
              isChanged: true,
              isChangedAssignment: true,
              module_name: mod_name,
            });
          } else {
            updateModified.push({
              exam_module_obtained_marks: row[data],
              isChanged: true,
              isChangedExam: true,
              module_name: mod_name,
            });
          }
        }
        if (form.isFieldTouched(data) && data === "attendance") {
          isAttendance = true;
        }
      });

      let id = key.id;

      let newArray = [];

      for (let i = 0; i < updateModified.length; i++) {
        let name = updateModified[i].module_name;
        let newArr = updateModified.filter((data) => name === data.module_name);
        if (newArr.length === 2) {
          let newObj = {
            ...newArr[0],
            ...newArr[1],
          };
          newArray.push(newObj);
        }
        newArray = uniqueObject(newArray, (it) => it.module_name);
      }

      for (let i = 0; i < newArray.length; i++) {
        let name = newArray[i].module_name;
        updateModified = updateModified.filter(
          (data) => name !== data.module_name
        );
      }

      updateModified.map((data) => {
        newArray.push(data);
      });

      let toSendArray = [];

      for (let i = 0; i < key.modified_data.length; i++) {
        let name = key.modified_data[i].module_name;

        let filterObj = newArray.find(
          (data) => data.module_name === name.toLowerCase()
        );
        if (filterObj) {
          let newObj = Object.assign({}, key.modified_data[i], filterObj);
          toSendArray.push(newObj);
        }
      }

      let timeOut;
      if (isAttendance) {
        dispatch(
          updateAttendance(id, {
            attendance: `${row["attendance"]}`,
          })
        );
        timeOut = setTimeout(() => {
          if (courseSelected !== "" && isCourseType) {
            dispatch(GetMarksheets(batch, courseSelected));
          } else {
            dispatch(GetMarksheets(batch));
          }
        }, 1000);
      }

      if (toSendArray.length > 0) {
        dispatch(updateModuleObtainedMarks(toSendArray, id));
        timeOut = setTimeout(() => {
          if (courseSelected !== "" && isCourseType) {
            dispatch(GetMarksheets(batch, courseSelected));
          } else {
            dispatch(GetMarksheets(batch));
          }
        }, 5000);
      }
      setEditingKey("");

      return () => clearTimeout(timeOut);
    } catch (errInfo) {}
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber
          style={{ textAlign: "center" }}
          // value={text}
          onChange={(e) => {
            let data = String(e);
            if (title.toLowerCase() === "attendance") {
              updateArr(record, index, Number(data), "attendance");
            } else {
              updateArr(record, index, Number(data), "ASSIGNMENT");
            }
          }}
        />
      ) : (
        <Input />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const setTotal = (data, index) => {
    // Set total
    data[index]["total_marks"] = parseFloat(
      parseFloat(data[index]["assignment_module_obtained_marks"]) +
        parseFloat(data[index]["exam_module_obtained_marks"])
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let editableCell = [];

  if (data && data.length > 0) {
    // data.map((x) => {
    data[0].modified_data.map((value) => {
      if (value && value.editable) {
        if (value) {
          editableCell.push({
            title: `Assignment ${value.module_name}`,
            dataIndex: `assignment_module_obtained_marks_${value.module_name
              .replace(" ", "_")
              .toLowerCase()}`,
            align: "center",
            width: 100,
            editable: true,
            type: "number",
            key: `assignment_module_obtained_marks_${value.module_name
              .replace(" ", "_")
              .toLowerCase()}`,
          });
        }

        if (value) {
          editableCell.push({
            title: `Exam ${value.module_name}`,
            dataIndex: `exam_module_obtained_marks_${value.module_name
              .replace(" ", "_")
              .toLowerCase()}`,
            align: "center",
            width: 100,
            editable: true,
            type: "number",
            key: `exam_module_obtained_marks_${value.module_name
              .replace(" ", "_")
              .toLowerCase()}`,
          });
        }
      }
    });
    // });

    data = data.map((x) => {
      let marksheetData = [];

      x.modified_data.map((item) => {
        marksheetData.push({
          [`assignment_module_obtained_marks_${item.module_name
            .replace(" ", "_")
            .toLowerCase()}`]: !isNaN(item.assignment_module_obtained_marks)
            ? item.assignment_module_obtained_marks
            : 0,
          // [`isAssignmentModuleObtainedMarks_${item.module_name.replace(" ", "_").toLowerCase()}`]: false,
          [`exam_module_obtained_marks_${item.module_name
            .replace(" ", "_")
            .toLowerCase()}`]: !isNaN(item.exam_module_obtained_marks)
            ? item.exam_module_obtained_marks
            : 0,
          // [`isExamModuleMbtainedMarks_${item.module_name.replace(" ", "_").toLowerCase()}`]: false,
        });
      });

      let obj = {
        ...x,
        name: x.student ? x.student.name : "N/A",
        student_code: x.student ? x.student.student_code : "N/A",
        batch_name: x.batch ? x.batch.name : "N/A",
      };

      return Object.assign({}, obj, ...marksheetData);
    });

    data = data.map((x) => {
      const editable = isEditing(x);
      return {
        ...x,
        action: (
          <span placement="bottom">
            {editable ? (
              <span>
                <Typography.Link
                  onClick={() => save(x)}
                  style={{ marginRight: 8 }}
                >
                  Save
                  {/* <Button loading={update_marks_loading}>Save</Button> */}
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <div>
                <div className="table-actions">
                  <Tooltip title="download" placement="bottom">
                    {x.pdf ? (
                      <a href={x.pdf}>
                        <DownloadOutlined
                          disabled={true}
                          style={{
                            color: "green",
                            cursor: "pointer",
                            marginRight: 10,
                            fontSize: 18,
                          }}
                        />
                      </a>
                    ) : (
                      <DownloadOutlined
                        disabled={true}
                        style={{
                          color: "grey",
                          cursor: "pointer",
                          marginRight: 10,
                          fontSize: 18,
                          paddingTop: "5px",
                        }}
                      />
                    )}
                  </Tooltip>
                  <Tooltip title="Veiw" placement="bottom">
                    {x.pdf ? (
                      <a
                        href={`${x.student.student_code}-${x.student.secret}`}
                        target="_blank"
                      >
                        <EyeFilled title="View" className="mr-3" />
                      </a>
                    ) : (
                      <a disabled="true">
                        <EyeFilled title="View" className="mr-3" />
                      </a>
                    )}
                  </Tooltip>
                  <Tooltip title="Edit" placement="bottom">
                    <Typography.Link
                      disabled={editingKey !== ""}
                      onClick={() => edit(x)}
                      className="mr-2 pad-top"
                    >
                      <EditFilled />
                    </Typography.Link>
                  </Tooltip>
                  <Tooltip title="Delete" placement="bottom">
                    <DeleteFilled
                      className="pad-top"
                      style={{
                        color: "red",
                        cursor: "pointer",
                        marginRight: 10,
                        fontSize: 18,
                      }}
                      onClick={() => {
                        Modal.confirm({
                          title:
                            "Are you sure delete this student's marksheet?",
                          icon: <ExclamationCircleOutlined />,
                          okText: "Yes",
                          okType: "danger",
                          cancelText: "No",
                          onOk() {
                            dispatch(deleteStudentMarksheet(x.id));
                            setTimeout(() => {
                              if (courseSelected !== "" && isCourseType) {
                                dispatch(GetMarksheets(batch, courseSelected));
                              } else {
                                dispatch(GetMarksheets(batch));
                              }
                            }, 1000);
                          },
                          onCancel() {
                            console.log("Cancel");
                          },
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </span>
        ),
      };
    });

    // setFinaData(data)
  }

  let columns = [
    {
      title: "Student Code",
      dataIndex: "student_code",
      key: "student_code",
      width: 100,
      fixed: "left",
      ...getColumnSearchProps("student_code"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
      fixed: "left",
      // width: "25%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      key: "batch_name",
      // width: "25%",
      width: 100,

      ...getColumnSearchProps("batch_name"),
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      width: 100,
      sorter: (a, b) => Number(a.percentage) - Number(b.percentage),
      ...getColumnSearchProps("percentage"),
    },
    // have to copy this

    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      width: 100,
      editable: true,
    },

    ...editableCell,
    // till here
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      width: 100,
      align: "center",
      // render: () => {
      //   return {
      //     props: {
      //       style: {
      //         textAlign: "center"
      //       }
      //     }

      //   }
      // }
    },
  ];
  const uploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_API_URL}/api/marksheet/marks/bulk-upload`,
    headers: {},
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "uploading") {
        setUploadLoading(true);
      }
      if (info.file.status === "done") {
        setUploadLoading(false);
        message.success(`${info.file.name} file uploaded successfully`);
        dispatch(getStudents());
      } else if (info.file.status === "error") {
        setUploadLoading(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const mergedColumns = columns.map((col) => {
    if (col) {
      if (!col.editable) {
        return col;
      }

      console.log("On cell data record ", col);

      return {
        ...col,
        // render: (object) => {
        //   // object && object.map(data => console.log("Object before on cell", data))
        //   console.log("Object before on cell", object)
        // },
        onCell: (record) => {
          console.log("Object before on cell ", col);
          return {
            record,
            inputType:
              col.dataIndex === "assignment_module_obtained_marks" ||
              col.dataIndex === "mark_module_obtained_marks" ||
              col.dataIndex === "attendance"
                ? "number"
                : "text",
            dataIndex:
              (col.dataIndex === "assignment_module_obtained_marks" &&
                record.modified_data.assignment_module_obtained_marks) ||
              (col.dataIndex === "mark_module_obtained_marks" &&
                record.modified_data.mark_module_obtained_marks) ||
              col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            text:
              (col.dataIndex === "assignment_module_obtained_marks" &&
                record.modified_data.assignment_module_obtained_marks) ||
              (col.dataIndex === "mark_module_obtained_marks" &&
                record.modified_data.mark_module_obtained_marks) ||
              "",
            // children: ,
          };
        },
      };
    }
  });

  const updateArr = (record, index, obtained_marks, type) => {
    const newData = [...data];
    console.log("record ", record, obtained_marks, newData, index);

    if (type === "ASSIGNMENT") {
      newData[index][`assignment_module_obtained_marks`] = obtained_marks;
      newData[index]["isChangedAssignment"] = true;

      newData[index]["isChanged"] = true;
      setTotal(newData, index);
    } else if (type === "EXAM") {
      newData[index][`exam_module_obtained_marks`] = obtained_marks;
      newData[index]["isChangedExam"] = true;

      newData[index]["isChanged"] = true;
      setTotal(newData, index);
    } else if (type === "attendance") {
      newData.map((val, index) => {
        if (val.id === record.id) {
          newData[index]["attendance"] = obtained_marks;
        }
      });
    }

    // updateTotalModule(newData);
    // updateTotalExam(newData);
    // setFinaData(newData);
  };

  console.log("merged columns in list marksheet ", item);

  return (
    <SidebarLayout active="playgorund">
      <Form form={form} component={false}>
        <Table
          loading={!item}
          bordered
          size="middle"
          scroll={{ x: true }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          title={() => (
            <div className="title-div">
              <HeadTitle
                title={"Marksheet"}
                setBatch={setBatch}
                batch_data={batch_data}
                batch={batch}
                courseSelected={courseSelected}
                setCourseSelected={setCourseSelected}
                isCourseType={isCourseType}
                setIsCourseType={setIsCourseType}
                dispatch={dispatch}
              />

              <div className="add">
                <Upload {...uploadProps}>
                  {uploadLoading ? (
                    <Button icon={<LoadingOutlined />} disabled>
                      Uploading...
                    </Button>
                  ) : (
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  )}
                </Upload>

                <Button
                  type="primary"
                  shape="round"
                  style={{ marginLeft: 5, marginRight: 5 }}
                  icon={<DownloadOutlined />}
                  size={"middle"}
                  onClick={() => {
                    if (courseSelected !== "" && isCourseType) {
                      message.info(`Generating Bulk Sheet for Batch ${batch} and Course Type ${courseSelected}`)
                      dispatch(generateFormat(batch, courseSelected))
                    }else if (!isCourseType){
                      dispatch(generateFormat(batch))
                    }
                  }}
                />

                <Button
                  type="primary"
                  shape="round"
                  style={{ marginRight: 12 }}
                  onClick={() => setOpen(true)}
                  size={"middle"}
                >
                  Add Marksheet
                </Button>
              </div>
            </div>
          )}
          dataSource={item && item.length === 0 ? item : data}
          columns={mergedColumns}
          rowClassName="editable-row"
          onRow={(record) => {
            return { onDoubleClick: () => edit(record) };
          }}
          pagination={{
            defaultPageSize: 10,
            position: ["bottomCenter"],
            total: data ? data.length : 0,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Form>
      <AddStudentMarksheet
        open={open}
        handleCancel={handleCancel}
        handleOk={handleOk}
        batch={batch}
        courseSelected={courseSelected}
        isCourseType={isCourseType}
      />
    </SidebarLayout>
  );
};

export default ListMarksheet2;
