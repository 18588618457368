const actions = {
  GET_BATCH_REQUEST: "GET_BATCH_REQUEST",
  GET_BATCH_SUCCESS: "GET_BATCH_SUCCESS",
  GET_BATCH_FAILED: "GET_BATCH_FAILED",
  ADD_BATCH_REQUEST: "ADD_BATCH_REQUEST",
  ADD_BATCH_SUCCESS: "ADD_BATCH_SUCCESS",
  ADD_BATCH_FAILED: "ADD_BATCH_FAILED",
  DELETE_BATCH_SUCCESS: "DELETE_BATCH_SUCCESS",
  DELETE_BATCH_FAILED: "DELETE_BATCH_FAILED",

  GENERATE_FORMAT_LOADING: "GENERATE_FORMAT_LOADING",
  GENERATE_CSV_LOADING: "GENERATE_CSV_LOADING",
  GENERATE_FORMAT_SUCCESS: "GENERATE_FORMAT_SUCCESS",
  GENERATE_CSV_SUCCESS: "GENERATE_CSV_SUCCESS",
  GENERATE_CSV_ERROR: "GENERATE_CSV_ERROR",

  GENERATE_MARKSHEET_ZIP_LOADING: "GENERATE_MARKSHEET_ZIP_LOADING",
  GENERATE_MARKSHEET_ZIP_SUCCESS: "GENERATE_MARKSHEET_ZIP_SUCCESS",
  GENERATE_MARKSHEET_ZIP_ERROR: "GENERATE_MARKSHEET_ZIP_ERROR",

  GET_ONE_BATCH_REQUEST: "GET_ONE_BATCH_REQUEST",
  GET_ONE_BATCH_SUCCESS: "GET_ONE_BATCH_SUCCESS",
  GET_ONE_BATCH_FAILED: "GET_ONE_BATCH_FAILED",

  UPDATE_BATCH_SUCCESS: "UPDATE_BATCH_SUCCESS",
  UPDATE_BATCH_REQUEST: "UPDATE_BATCH_REQUEST",
  UPDATE_BATCH_FAILED: "UPDATE_BATCH_FAILED",

  BULK_BATCH_MARKSHEET_GEN_SUCCESS: "BULK_BATCH_MARKSHEET_GEN_SUCCESS",
  BULK_BATCH_MARKSHEET_GEN_REQUEST: "BULK_BATCH_MARKSHEET_GEN_REQUEST",
  BULK_BATCH_MARKSHEET_GEN_FAILED: "BULK_BATCH_MARKSHEET_GEN_FAILED",

  bulkGenRequest: () => {
    return {
      type: actions.BULK_BATCH_MARKSHEET_GEN_REQUEST
    }
  },

  bulkGenSuccess: () => {
    return {
      type: actions.BULK_BATCH_MARKSHEET_GEN_SUCCESS
    }
  },

  bulkGenFailed: () => {
    return {
      type: actions.BULK_BATCH_MARKSHEET_GEN_FAILED
    }
  },

  updateBatchRequest: () => {
    return {
      type: actions.UPDATE_BATCH_REQUEST,
    };
  },

  updateBatchSuccess: (payload) => {
    return {
      type: actions.UPDATE_BATCH_SUCCESS,
      payload,
    };
  },

  updateBatchError: (err) => {
    return {
      type: actions.UPDATE_BATCH_FAILED,
      error: err,
    };
  },

  getOneBatchRequest: () => {
    return {
      type: actions.GET_ONE_BATCH_REQUEST,
    };
  },

  getOneBatchSuccess: (payload) => {
    return {
      type: actions.GET_ONE_BATCH_SUCCESS,
      payload,
    };
  },

  getOneBatchError: (err) => {
    return {
      type: actions.GET_ONE_BATCH_FAILED,
      error: err,
    };
  },

  generateFormatLoading: () => {
    return {
      type: actions.GENERATE_FORMAT_LOADING,
    };
  },
  generateCSVLoading: () => {
    return {
      type: actions.GENERATE_CSV_LOADING,
    };
  },

  generateFormatSuccess: (payload) => {
    return {
      type: actions.GENERATE_FORMAT_SUCCESS,
      payload,
    };
  },
  generateCSVSuccess: (payload) => {
    return {
      type: actions.GENERATE_FORMAT_SUCCESS,
      payload,
    };
  },

  generateFormatError: () => {
    return {
      type: actions.GENERATE_FORMAT_ERROR,
    };
  },
  generateCSVError: () => {
    return {
      type: actions.GENERATE_FORMAT_ERROR,
    };
  },

  generateZIPLoading: () => {
    return {
      type: actions.GENERATE_MARKSHEET_ZIP_LOADING,
    };
  },

  generateZIPSuccess: (payload) => {
    return {
      type: actions.GENERATE_MARKSHEET_ZIP_SUCCESS,
      payload,
    };
  },

  generateZIPError: () => {
    return {
      type: actions.GENERATE_MARKSHEET_ZIP_ERROR,
    };
  },

  deleteBatchSuccess: (id) => {
    return {
      type: actions.DELETE_BATCH_SUCCESS,
      payload: id,
    };
  },

  deleteBatchFailed: (payload) => {
    return {
      type: actions.DELETE_BATCH_FAILED,
      payload,
    };
  },

  getBatchRequest: () => {
    return {
      type: actions.GET_BATCH_REQUEST,
    };
  },

  getBatchSuccess: (payload) => {
    return {
      type: actions.GET_BATCH_SUCCESS,
      payload,
    };
  },

  getBatchFailed: (payload) => {
    return {
      type: actions.GET_BATCH_FAILED,
      payload,
    };
  },

  addBatchRequest: () => {
    return {
      type: actions.ADD_BATCH_REQUEST,
    };
  },

  addBatchSuccess: (payload) => {
    return {
      type: actions.ADD_BATCH_SUCCESS,
      payload,
    };
  },

  addBatchFailed: (payload) => {
    return {
      type: actions.ADD_BATCH_FAILED,
      error: payload,
    };
  },
};

export { actions };
