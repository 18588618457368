import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  bulkState: false,
};
const BulkMarksheetState = createSlice({
  name: "Bulk Marksheet State",
  initialState,
  reducers: {
    BulkMarksheetStateSuccess(state, action) {
      state.bulkState = action.payload;
    },
  },
});

export const GetBulkMarksheetState = (isTrue) => async (dispatch) => {
  console.log("is true value", isTrue);
  try {
    dispatch(BulkMarksheetStateSuccess(isTrue));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
  }
};

const { BulkMarksheetStateSuccess } = BulkMarksheetState.actions;
export default BulkMarksheetState.reducer;
