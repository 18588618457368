import React, { useEffect, useRef, useState } from "react";
import {
  SearchOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Tooltip,
} from "antd";
import Highlighter from "react-highlight-words";
import SidebarLayout from "../Layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getSchool,
  deleteSChool,
} from "../../Redux/action-creators/school/school";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const SchoolList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { loading, data } = useSelector((state) => state.school);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    dispatch(getSchool());
  }, [dispatch]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "20%",
      ...getColumnSearchProps("code"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  if (data && data.length) {
    data = data.map((x) => {
      return {
        ...x,
        action: (
          <div className="table-actions">
            <Tooltip title="Edit" placement="bottom">
              <EditFilled
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginRight: 10,
                  fontSize: 18,
                }}
                onClick={() => navigate(`/school/update/${x.id}`)}
              />
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <DeleteFilled
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: 10,
                  fontSize: 18,
                }}
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure delete this school?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk() {
                      console.log("OK", x.id);
                      dispatch(deleteSChool(x.id));
                      // dispatch(getSchool())
                      // navigate("/school")
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });
  }

  const [open, setOpen] = useState(false);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  return (
    <SidebarLayout active="school">
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        title={() => (
          <Row>
            <Typography.Title level={3} style={{ margin: 0 }}>
              All Schools
            </Typography.Title>
            <Button
              type="primary"
              style={{ marginLeft: "auto" }}
              onClick={() => navigate("/school/add")}
            >
              Add School
            </Button>
          </Row>
        )}
        pagination={{
          defaultPageSize: 10,
          position: ["bottomCenter"],
          total: data ? data.length : 0,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </SidebarLayout>
  );
};

export default SchoolList;
