import React from "react";
import { Fragment, useEffect, useState } from "react";
import Navbar from "../../Components/Student/Navbar";
import Footer from "../Verify/Footer";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loading from "../../Components/UI/Loading";
import CertBody from "../Verify/CertBody";
import { useDispatch } from "react-redux";
import { verifyStudentCertificate } from "../../Redux/redux_toolkit/certificate/certificateSsr";

const CartiVerify = ({ socket }) => {
  const dispatch = useDispatch();
  let { student_id } = useParams();

  const [verify_marksheet, setVerify_marksheet] = useState();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    socket.emit("get_verify_page", student_id);
    dispatch(verifyStudentCertificate(student_id));
  }, []);

  useEffect(() => {
    socket.on("take_verify_link", (data) => {
      setVerify_marksheet(data);
      console.log("This is important think ", data, student_id);
      setloading(false);
    });
    return () => {
      socket.removeAllListeners();
    };
  }, [socket]);

  const addAnalytic = (data) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/student/add-analytics`,
          {
            student_id: verify_marksheet.student_id,
            marksheet_id: verify_marksheet.id,
            ...data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => console.log(res.data))
        .catch((er) => console.log(er));
    } catch (error) {
      console.log(error);
    }
  };

  console.log("verify certificate ", verify_marksheet);

  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content="Verify page" />
        <meta property="og:image" content="//media.example.com/ 1234567.jpg" />
        <meta property="og:description" content="Verify page description" />

        {/* Primary Meta Tags */}
        <meta
          name="title"
          content={`${
            verify_marksheet && verify_marksheet.batch.program
          } | IIDE - The Digital School`}
        />
        <meta
          name="description"
          content={`${
            verify_marksheet && verify_marksheet.batch.program
          } | IIDE - The Digital School`}
        />

        {/* Open Graph / Facebook */}
        {/* <meta property="og:description" content={`${ verify_marksheet && verify_marksheet.batch.program } | IIDE.pdf`} /> */}

        {/* Twitter */}
        <meta
          property="twitter:card"
          content={`${
            verify_marksheet && verify_marksheet.batch.program
          } | IIDE - The Digital School`}
        />
        <meta
          property="twitter:url"
          content={
            verify_marksheet &&
            `${process.env.REACT_APP_API_URL}/${verify_marksheet.image}`
          }
        />
        <meta
          property="twitter:title"
          content={`${
            verify_marksheet && verify_marksheet.batch.program
          } | IIDE - The Digital School`}
        />
        {/* <meta property="twitter:description" content={`${ verify_marksheet && verify_marksheet.batch.program } | IIDE.pdf`}  /> */}
        <meta
          property="twitter:image"
          content={
            verify_marksheet &&
            `${process.env.REACT_APP_API_URL}/${verify_marksheet.image}`
          }
        />
      </Helmet>
      <Navbar />
      {loading ? (
        <Fragment>
          <Loading />
        </Fragment>
      ) : (
        <>
          <CertBody
            verify_marksheet={verify_marksheet}
            addAnalytic={addAnalytic}
          />
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default CartiVerify;
