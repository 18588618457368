import React from "react";
import { notification } from "antd";
import SidebarLayout from "../Pages/Layout/SidebarLayout"

const Notification = ({ title, message }) => {

  const [api, contextHolder] = notification.useNotification();

  api.open({
    message: title,
    description: message,
    duration: 0,
  });

  return (
    <SidebarLayout>
      {contextHolder}
    </SidebarLayout>
  )
};

export default Notification;
