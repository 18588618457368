const actions = {
  GET_STUDENT_MARKSHEET_REQUEST: "GET_STUDENT_MARKSHEET_REQUEST",
  GET_STUDENT_MARKSHEET_SUCCESS: "GET_STUDENT_MARKSHEET_SUCCESS",

  ADD_STUDENT_MARKSHEET_SUCCESS: "ADD_STUDENT_MARKSHEET_SUCCESS",

  GET_STUDENT_MARKSHEET_MODULES_REQUEST:
    "GET_STUDENT_MARKSHEET_MODULES_REQUEST",
  GET_STUDENT_MARKSHEET_MODULES_SUCCESS:
    "GET_STUDENT_MARKSHEET_MODULES_SUCCESS",

  UPDATE_MODULE_OBTAINED_MARKS_REQUEST: "UPDATE_MODULE_OBTAINED_MARKS_REQUEST",
  UPDATE_MODULE_OBTAINED_MARKS_SUCCESS: "UPDATE_MODULE_OBTAINED_MARKS_SUCCESS",

  GEN_MARKSHEET_PDF_REQUEST: "GEN_MARKSHEET_PDF_REQUEST",
  GEN_MARKSHEET_PDF_SUCCESS: "GEN_MARKSHEET_PDF_SUCCESS",

  CALC_FINAL_MARKS_REQUEST: "CALC_FINAL_MARKS_REQUEST",
  CALC_FINAL_MARKS_SUCCESS: "CALC_FINAL_MARKS_SUCCESS",

  UPDATE_ATTENDANCE_REQUEST: "UPDATE_ATTENDANCE_REQUEST",
  UPDATE_ATTENDANCE_SUCCESS: "UPDATE_ATTENDANCE_SUCCESS",

  DELETE_MARKSHEET_SUCCESS: "DELETE_MARKSHEET_SUCCESS",

  SEARCH_STUDENT_MARKSHEET_LOADING: "SEARCH_STUDENT_MARKSHEET_LOADING",
  SEARCH_STUDENT_MARKSHEET_SUCCESS: "SEARCH_STUDENT_MARKSHEET_SUCCESS",
  SEARCH_STUDENT_MARKSHEET_ERROR: "SEARCH_STUDENT_MARKSHEET_ERROR",
  SEARCH_STUDENT_MARKSHEET_RESET: "SEARCH_STUDENT_MARKSHEET_RESET",

  VERIFY_STUDENT_MARKSHEET_LOADING: "VERIFY_STUDENT_MARKSHEET_LOADING",
  VERIFY_STUDENT_MARKSHEET_SUCCESS: "VERIFY_STUDENT_MARKSHEET_SUCCESS",
  VERIFY_STUDENT_MARKSHEET_ERROR: "VERIFY_STUDENT_MARKSHEET_ERROR",

  verifyStudentRequest: () => {
    return {
      type: actions.VERIFY_STUDENT_MARKSHEET_LOADING,
    };
  },

  verifyStudentSuccess: (payload) => {
    return {
      type: actions.VERIFY_STUDENT_MARKSHEET_SUCCESS,
      payload,
    };
  },

  verifyStudentFailed: (payload) => {
    return {
      type: actions.VERIFY_STUDENT_MARKSHEET_ERROR,
      payload,
    };
  },

  marksheetSearchReset: () => {
    return {
      type: actions.SEARCH_STUDENT_MARKSHEET_RESET,
    };
  },

  marksheetSearchLoading: () => {
    return {
      type: actions.SEARCH_STUDENT_MARKSHEET_LOADING,
    };
  },

  marksheetSearchSuccess: (payload) => {
    return {
      type: actions.SEARCH_STUDENT_MARKSHEET_SUCCESS,
      payload,
    };
  },

  marksheetSearchFailed: (payload) => {
    return {
      type: actions.SEARCH_STUDENT_MARKSHEET_ERROR,
      payload,
    };
  },

  deleteMarksheetSuccess: (id) => {
    return {
      type: actions.DELETE_MARKSHEET_SUCCESS,
      payload: id,
    };
  },

  calcFinalMarksRequest: () => {
    return {
      type: actions.CALC_FINAL_MARKS_REQUEST,
    };
  },

  calcFinalMarksSuccess: () => {
    return {
      type: actions.CALC_FINAL_MARKS_SUCCESS,
    };
  },

  updateAttendanceRequest: () => {
    return {
      type: actions.UPDATE_ATTENDANCE_REQUEST,
    };
  },

  updateAttendanceSuccess: () => {
    return {
      type: actions.UPDATE_ATTENDANCE_SUCCESS,
    };
  },

  genMarksheetRequest: () => {
    return {
      type: actions.GEN_MARKSHEET_PDF_REQUEST,
    };
  },

  genMarksheetSuccess: () => {
    return {
      type: actions.GEN_MARKSHEET_PDF_SUCCESS,
    };
  },

  updateModuleObtainedMarksRequest: () => {
    return {
      type: actions.UPDATE_MODULE_OBTAINED_MARKS_REQUEST,
    };
  },

  updateModuleObtainedMarksSuccess: (payload) => {
    return {
      type: actions.UPDATE_MODULE_OBTAINED_MARKS_SUCCESS,
      payload,
    };
  },

  addStudentMarksheetSuccess: (payload) => {
    return {
      type: actions.ADD_STUDENT_MARKSHEET_SUCCESS,
      payload,
    };
  },

  getStudentMarksheetRequest: () => {
    return {
      type: actions.GET_STUDENT_MARKSHEET_REQUEST,
    };
  },

  getStudentMarksheetSuccess: (payload) => {
    return {
      type: actions.GET_STUDENT_MARKSHEET_SUCCESS,
      payload,
    };
  },

  getStudentMarksheetModulesRequest: () => {
    return {
      type: actions.GET_STUDENT_MARKSHEET_MODULES_REQUEST,
    };
  },

  getStudentMarksheetModulesSuccess: (payload) => {
    return {
      type: actions.GET_STUDENT_MARKSHEET_MODULES_SUCCESS,
      payload,
    };
  },
};

export { actions };
