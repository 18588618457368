import React, { useEffect, useRef, useState } from "react";
import {
  SearchOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Tooltip,
  Upload,
  message,
} from "antd";
import Highlighter from "react-highlight-words";
import SidebarLayout from "../../Layout/SidebarLayout";
import AddStudent from "./AddStudent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteStudent,
  getStudents,
} from "../../../Redux/action-creators/students/students";
import { getBatch } from "../../../Redux/action-creators/batch/batch";

const ListStudent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { loading, data } = useSelector((state) => state.student);
  let { data: batch_data } = useSelector((state) => state.batch);
  const [updtData, setUpdtData] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleEdit = (x) => {
    console.log("handle update", x)
    setUpdtData(x)
    console.log("handle update 1", updtData)
  }

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex] !== null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
    ,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    dispatch(getStudents());
    dispatch(getBatch());
  }, [dispatch]);

  const columns = [
    {
      title: "Student Code",
      dataIndex: "student_code",
      key: "student_code",
      width: "40%",
      ...getColumnSearchProps("student_code"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "40%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "40%",
      ...getColumnSearchProps("type"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  if (data && data.length) {
    data = data.map((x) => {
      console.log("Data completed ",x)
      return {
        ...x,
        action: (
          <div className="table-actions">
            <Tooltip title="Edit" placement="bottom">
              <EditFilled
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginRight: 10,
                  fontSize: 18,
                }}
                onClick={() => {
                  handleEdit(x);
                  setOpen(true);
                }}
              />
            </Tooltip>
            {/* <Tooltip title="Delete" placement="bottom">
              <DeleteFilled
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: 10,
                  fontSize: 18,
                }}
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure delete this student?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk() {
                      console.log("OK", x.id);
                      dispatch(deleteStudent(x.id));
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              />
            </Tooltip> */}
          </div>
        ),
      };
    });
  }

  const [open, setOpen] = useState(false);

  const handleOk = () => {
    setOpen(false);
    setUpdtData(null);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
    setUpdtData(null);
  };

  const uploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_API_URL}/api/student/bulk-upload`,
    headers: {},
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "uploading") {
        setUploadLoading(true);
      }
      if (info.file.status === "done") {
        setUploadLoading(false);
        message.success(`${info.file.name} file uploaded successfully`);
        dispatch(getStudents());
      } else if (info.file.status === "error") {
        setUploadLoading(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <SidebarLayout active="student">
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        title={() => (
          <Row>
            <Typography.Title level={3} style={{ margin: 0 }}>
              All Students
            </Typography.Title>

            <Button
              type="primary"
              style={{ marginLeft: "auto", marginRight: 12 }}
              onClick={() => setOpen(true)}
            >
              Add Student
            </Button>
            <Button
              type="dashed"
              style={{  marginRight: 12 }}
              onClick={() => window.open('/iide_student_upload_fromat.xlsx')}
            >
              Download Format
            </Button>
            <Upload {...uploadProps}>
              {uploadLoading ? (
                <Button icon={<LoadingOutlined />} disabled>
                  Uploading...
                </Button>
              ) : (
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              )}
            </Upload>
          </Row>
        )}
        pagination={{
          defaultPageSize: 10,
          position: ["bottomCenter"],
          total: data ? data.length : 0,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
      <AddStudent
        open={open}
        handleCancel={handleCancel}
        handleOk={handleOk}
        edit={updtData}
      />
    </SidebarLayout>
  );
};

export default ListStudent;
