import axios from "axios";
import { getHeaders } from "../../action-creators/auth/auth";
import { actions } from "../../actions/students/student.action";
import { message } from "antd";

const URL = process.env.REACT_APP_API_URL;

export const getStudents = () => async (dispatch) => {
  try {
    dispatch(actions.getStudentRequest());

    const route = `${URL}/api/student`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(route, { headers });

    dispatch(actions.getStudentSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
  }
};

export const addNewStudent = (nData, edit, id) => async (dispatch) => {
  try {
    if (edit) {
      nData = {id, ...nData}
      dispatch(actions.updateStudentRequest());
      const headers = dispatch(getHeaders());
      const route = `${URL}/api/student/update`;
      const { data } = await axios.put(route, nData, { headers });
      console.log("Update data ",data);
    } else {
      dispatch(actions.addStudentRequest());
      const headers = dispatch(getHeaders());
      const route = `${URL}/api/student`;
      const { data } = await axios.post(route, nData, { headers });
      console.log(data);
      message.success("Student added sucessfully");
      dispatch(actions.addStudentSuccess(data.data));
    }
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
  }
};

export const deleteStudent = (id) => async (dispatch) => {
  try {
    const headers = dispatch(getHeaders());

    const route = `${URL}/api/student/${id}`;

    const { data } = await axios.delete(route, { headers });

    console.log(data);
    message.success("Student delete sucessfully");
    dispatch(actions.deleteStudentSuccess(id))
  } catch (error) {
    const msg = error.response.data.message;
    const errorMessage = msg ? msg : error.message;
    console.log(msg);
    message.error(errorMessage);
  }
};