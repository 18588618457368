import { actions } from "../../actions/evaluations/evaluation.action";

const initialState = {
  data: [],
  loading: false,
  query_data: null,
};

const evaluationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_EVALUATION_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
        query_data: null,
      };
    case actions.GET_EVALUATION_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.QUERY_EVALUATION_MODULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.QUERY_EVALUATION_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        query_data: action.payload,
      };
    case actions.ADD_EVALUATION_MASTER_REQUEST:
      return {
        ...state,
        insertLoading: true,
      };
    case actions.ADD_EVALUATION_MASTER_SUCCESS:
      return {
        insertLoading: false,
        data: [...state.data, action.payload],
      };

    case actions.ADD_EVALUATION_MODULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_EVALUATION_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_EVALUATION_SUCCESS:
      return {
        ...state,
        data: state.data.filter((x) => x.id !== action.payload),
      };
    default:
      return state;
  }
};

export { evaluationReducer };
