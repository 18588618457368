import { actions } from "../../actions/students/student.action";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const StudentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_STUDENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case actions.ADD_STUDENT_REQUEST:
      return {
        ...state,
        add_student_loading: true,
      };
    case actions.ADD_STUDENT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case actions.DELETE_STUDENT_SUCESS:
      return {
        ...state,
        data: state.data.filter((x) => x.id !== action.payload),
      };
    default:
      return state;
  }
};

export { StudentReducer };
