import {
  Button,
  Input,
  Space,
  Table,
  Menu,
  Dropdown,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import HeadTitle from "./title";

const SendMailModel = ({
  batchDetail,
  socket,
  send,
  batch,
  setBatch,
  headtitle,
  batchdata = { batchdata },
  courseSelected,
  setCourseSelected,
  isCourseType,
  setIsCourseType,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    socket.on("mail-update", (batch_socket) => {
      console.log("mail update socket call ", batch_socket);
    });

    socket.on("done_sending_email", () => {
      console.log("reseting email");
      setSelectedRowKeys([]);
    });
  }, [socket]);



  // const batchMenu = (
  //   <Menu>
  //     {batchdata.map((data) => (
  //       <Menu.Item
  //         key={data.id}
  //         onClick={() => {
  //           setBatch(data.name);
  //           socket.emit("get-batch-detail", data.name);
  //         }}
  //       >
  //         {data.name}
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // if (batchDetail) {
  //   batchDetail.map((fdata) => {
  //     if (!fdata.isCertCreated) {
  //       isAnyCertNotCreated = true;
  //     }
  //   });
  // }

  const columns = [
    {
      title: "Student Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Program",
      dataIndex: "program",
      key: "program",
      ...getColumnSearchProps("program"),
    },
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      key: "batch_name",
      ...getColumnSearchProps("batch_name"),
    },
    {
      title: "Mail Sent",
      dataIndex: "mailCount",
      key: "mailCount",
      ...getColumnSearchProps("mailCount"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  batchDetail = batchDetail
    .filter((data) => {
      return (
        (headtitle === "Marksheet"
          ? data.image !== null && data.pdf !== null
          : data.certificateImage !== null && data.certificatePdf !== null) &&
        data.student.email !== null
      );
    })
    .map((val) => {
      console.log("Value from val ", val);
      return {
        ...val,
        key: val.id,
        batch_name: val.batch.name,
        program: val.batch.program,
        email: val.student.email,
        name: val.student.name,
        mailCount: val.student.noOfMail,
      };
    });

  console.log("data after processing ", batchDetail);
  return (
    <Table
      columns={columns}
      dataSource={batchDetail}
      // loading={loading}
      rowSelection={rowSelection}
      title={() => (
        <div className="title-div">
          <HeadTitle
            title={headtitle}
            setBatch={setBatch}
            batch={batch}
            batch_data={batchdata}
            courseSelected={courseSelected}
            setCourseSelected={setCourseSelected}
            isCourseType={isCourseType}
            setIsCourseType={setIsCourseType}
            // socket={socket}
            // isMail={true}
          />
          {/* <Button
              type="primary"
              onClick={() => start()}
              disabled={!hasSelected}
              style={{marginLeft: "auto"}}
            >Generate Certificate</Button> */}
          <Button
            type="primary"
            onClick={() => send(selectedRowKeys)}
            style={{ marginLeft: "auto" }}
          >
            Send Email
          </Button>
        </div>
      )}
      pagination={{
        defaultPageSize: 100,
        position: ["bottomCenter"],
        total: batchDetail ? batchDetail.length : 0,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
    />
  );
};

export default SendMailModel;
