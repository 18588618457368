import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row, Tooltip, Input, Card } from "antd";
import { SearchOutlined, EyeFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Navbar from '../../Components/Student/Navbar';
import {
  searchStudentMarksheet,
  searchStudentMarksheetReset,
} from "../../Redux/action-creators/marksheet/marksheet";
import { Helmet } from "react-helmet";

const SearchStudentData = () => {
  // const dispatch = useDispatch();
  // const { loading, search_marksheet_data } = useSelector(
  //   (state) => state.studentMarksheet
  // );
  // const [studentCode, setStudentCode] = useState("");

  // useEffect(() => {
  //   dispatch(searchStudentMarksheetReset());
  // }, [dispatch]);

  // const handleSubmit = () => {
  //   console.log(studentCode, "studentCode");
  //   dispatch(searchStudentMarksheet(studentCode));
  // };

  return (
    <Fragment>
      <Helmet>
        <meta http-equiv="refresh" content="0; URL=https://www.iide.co" />
      </Helmet>
    <Navbar/>
        {/* <Row justify="center" align="middle" style={{ paddingTop: "30vh" }}>
          <Col span={4}>
            <Input
              size="large"
              placeholder="Student ID"
              required
              disabled={loading}
              onChange={(e) => setStudentCode(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col span={2}>
            <Tooltip title="Search">
              <Button
                loading={loading}
                disabled={loading}
                type="primary"
                size="large"
                onClick={() => handleSubmit()}
              >
                Search
              </Button>
            </Tooltip>
          </Col>
        </Row>

        <Row justify="center" align="middle" style={{ paddingTop: 30 }}>
          {search_marksheet_data &&
            search_marksheet_data.map((x) => (
              <Col span={3}>
                <Card
                  style={{
                    marginRight: 10,
                  }}
                  actions={[
                    <EyeFilled
                      key="View"
                      onClick={() =>
                        window.open(`${process.env.REACT_APP_API_URL}/${x.pdf}`)
                      }
                    />,
                  ]}
                >
                  <Card.Meta
                    title={x.student.name}
                    description={`From Batch ${x.batch.name}`}
                  />
                </Card>
              </Col>
            ))}
        </Row> */}
    </Fragment>
  );
};

export default SearchStudentData;
