import { Button, Modal, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewStudent,
  getStudents,
} from "../../../Redux/action-creators/students/students";
import { courses } from "../../../utils/list";

const AddStudent = ({ open, handleCancel, handleOk, edit }) => {

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const handleFinish = (values, isEdit) => {
    console.log("edit handle data id inside ", isEdit, edit);
    if (!isEdit) {
      dispatch(addNewStudent(values, false));
    } else {
      dispatch(addNewStudent(values, true, edit.id));
    }
    form.resetFields();
    handleCancel();
  };

  const handleReset = () => {
    form.resetFields();
    handleCancel();
  };

  useEffect(() => {
    console.count("handle useEffect");
    if (edit) {
      form.setFieldValue("student_code", edit.student_code);
      form.setFieldValue("email", edit.email);
      form.setFieldValue("name", edit.name);
      form.setFieldValue("type", edit.type)
    }
  }, [edit]);

  console.log("edit value from add student", edit);

  return (
    <Modal
      title={edit ? "Edit Student" : "Add Student"}
      open={open}
      confirmLoading={false}
      onCancel={handleReset}
      footer={null}
    >
      <Form
        layout={"vertical"}
        form={form}
        initialValues={{
          layout: "vertical",
          remember: true,
          student_code: "",
          name: "",
          type: ""
        }}
        autoComplete="off"
        onFinish={(value) => {
          handleFinish(
            value,
            edit && Object.keys(edit).length > 0 ? true : false
          );
          dispatch(getStudents());
        }}
      >
        <Form.Item
          label="Student ID"
          name="student_code"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Please enter student code!",
            },
            {
              pattern: /^[A-Z0-9]{1,15}$/,
              message: "Uppercase Character only and limit is 15 Character",
            },
          ]}
        >
          <Input placeholder="Enter student code..." />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter student Email!",
            },
            {
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: "Invalid Email Address",
            },
          ]}
        >
          <Input placeholder="Enter student name..." />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter student name!",
            },
            {
              pattern: /^[ a-zA-Z]{1,35}$/,
              message: "Name must in between 35 Character",
            },
          ]}
        >
          <Input placeholder="Enter student name..." />
        </Form.Item>
        <Form.Item
          label="Student Course Type"
          name="type"
          rules={[
            {
              required: true,
              message: "Please select student Course type",
            },
          ]}
        >
          <Select
            placeholder="Select student Course type"
            options={courses.map(val => val)}
          />
        </Form.Item>
        {/* <Form.Item
          label="Batch"
          name="batch_id"
          rules={[
            {
              required: true,
              message: "Please select batch",
            },
          ]}
        >
          <Select
            loading={loading}
            placeholder="Select batch"
            options={
              data &&
              data.map((x) => {
                return {
                  label: x.name,
                  value: x.id,
                };
              })
            }
          />
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddStudent;
