import { actions } from "../../actions/marksheet/marksheet.action";

const initialState = {
  data: null,
  loading: false,
  new_data: null,
  marksheet_data: null,
  search_marksheet_data: null,
  updatemarksSuccess: false,
  pdf_gen_loading: false,
  pdf_gen_success: false,
  calc_final_marks_loading: false,
  calc_final_marks_success: false,
  update_attendance_loading: false,
  update_attendance_success: false,
  verify_marksheet: null,
};

const StudentMarksheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_STUDENT_MARKSHEET_REQUEST:
      return {
        ...state,
        new_data: null,
        loading: true,
        marksheet_data: null,
        updatemarksSuccess: false,
        pdf_gen_loading: false,
        pdf_gen_success: false,
        calc_final_marks_success: false,
        update_attendance_loading: false,
        update_attendance_success: false,
        search_marksheet_data: null,
      };

    case actions.VERIFY_STUDENT_MARKSHEET_LOADING:
      return {
        ...state,
        loading: true,
        verify_marksheet: null
      };

    case actions.VERIFY_STUDENT_MARKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        verify_marksheet: action.payload,
      };

    case actions.VERIFY_STUDENT_MARKSHEET_ERROR:
      return {
        ...state,
        loading: false,
        err: action.payload,
      };
    case actions.GET_STUDENT_MARKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.UPDATE_MODULE_OBTAINED_MARKS_REQUEST:
      return {
        ...state,
        update_marks_loading: true,
        updatemarksSuccess: false,
      };
    case actions.UPDATE_MODULE_OBTAINED_MARKS_SUCCESS:
      return {
        ...state,
        update_marks_loading: false,
        updatemarksSuccess: true,
      };
    case actions.GET_STUDENT_MARKSHEET_MODULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_STUDENT_MARKSHEET_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        marksheet_data: action.payload,
      };
    case actions.ADD_STUDENT_MARKSHEET_SUCCESS:
      if (Array.isArray(state.data)) {
        // Concatenate action.payload to state.data if it's an array
        return {
          ...state,
          data: [...state.data, action.payload],
        };
      } else {
        // If state.data is not an array, create a new array with action.payload
        return {
          ...state,
          data: [action.payload],
        };
      }

    case actions.GEN_MARKSHEET_PDF_REQUEST:
      return {
        ...state,
        pdf_gen_loading: true,
      };
    case actions.GEN_MARKSHEET_PDF_SUCCESS:
      return {
        ...state,
        pdf_gen_loading: false,
        pdf_gen_success: true,
      };
    case actions.CALC_FINAL_MARKS_REQUEST:
      return {
        ...state,
        calc_final_marks_loading: true,
      };
    case actions.CALC_FINAL_MARKS_SUCCESS:
      return {
        ...state,
        calc_final_marks_loading: false,
        calc_final_marks_success: true,
      };
    case actions.UPDATE_ATTENDANCE_REQUEST:
      return {
        ...state,
        update_attendance_loading: true,
      };
    case actions.UPDATE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        update_attendance_loading: false,
        update_attendance_success: true,
      };
    case actions.DELETE_MARKSHEET_SUCCESS:
      return {
        ...state,
        data: state.data && state.data.filter((x) => x.id !== action.payload),
      };
    case actions.SEARCH_STUDENT_MARKSHEET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actions.SEARCH_STUDENT_MARKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        search_marksheet_data: action.payload,
      };
    case actions.SEARCH_STUDENT_MARKSHEET_ERROR:
      return {
        ...state,
        loading: false,
        search_marksheet_error: action.payload,
      };
    case actions.SEARCH_STUDENT_MARKSHEET_RESET:
      return {
        ...state,
        loading: false,
        search_marksheet_error: null,
        search_marksheet_data: null,
      };
    default:
      return state;
  }
};

export { StudentMarksheetReducer };
