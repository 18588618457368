import axios from "axios";
import { getHeaders } from "../auth/auth";
import { actions } from "../../actions/evaluations/evaluation.action";
import { message } from "antd";

const URL = process.env.REACT_APP_API_URL;

export const getEvaluationMaster = () => async (dispatch) => {
  try {
    dispatch(actions.getEvaluationRequest());

    const reqRoute = `${URL}/api/evaluation/master`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(reqRoute, {
      headers,
    });

    console.log(data.data);

    dispatch(actions.getEvaluationSucess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    
    message.error(msg);
  }
};

export const queryEvaluationModules = (query) => async (dispatch) => {
  try {
    dispatch(actions.queryEvaluationRequest());

    const reqRoute = `${URL}/api/evaluation/query/all`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.post(reqRoute, query, {
      headers,
    });

    console.log(data.data, "data");

    // if (!data.data.length) message.warning("No modules found, please add one!");
    dispatch(actions.queryEvaluationSucess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    message.error(msg);
  }
};

export const createEvaluationMaster = (rData) => async (dispatch) => {
  try {
    dispatch(actions.addEvaluationRequest());

    const reqRoute = `${URL}/api/evaluation/master/add`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.post(reqRoute, rData, {
      headers,
    });

    console.log(data.data);

    message.success("Evaluation created sucessfully");
    dispatch(actions.addEvaluationSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    message.error(msg);
  }
};

export const duplicateEvaluationMaster = (rData, id) => async (dispatch) => {
  try {
    dispatch(actions.addEvaluationRequest());

    const reqRoute = `${URL}/api/evaluation/master/duplicate`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.post(
      reqRoute,
      { ...rData, id },
      {
        headers,
      }
    );

    console.log(data.data);

    message.success("Evaluation duplicated sucessfully");
    dispatch(actions.addEvaluationSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    message.error(msg);
  }
};

export const createEvaluationModule =
  (evaluation_master_id, rData) => async (dispatch) => {
    try {
      dispatch(actions.addEvaluationModuleRequest());

      const reqRoute = `${URL}/api/evaluation/${evaluation_master_id}`;

      const headers = dispatch(getHeaders());

      const { data } = await axios.post(reqRoute, rData, {
        headers,
      });

      console.log(data.data);

      message.success("Evaluation modules added sucessfully");
      dispatch(actions.addEvaluationModuleSuccess());
    } catch (error) {
      const msg = error.response.data.message;
      message.error(msg);
    }
  };

export const updateEvaluationModule =
  (evaluation_master_id, rData) => async (dispatch) => {
    try {
      dispatch(actions.addEvaluationModuleRequest());

      const reqRoute = `${URL}/api/evaluation/${evaluation_master_id}`;

      const headers = dispatch(getHeaders());

      const { data } = await axios.put(reqRoute, rData, {
        headers,
      });

      console.log(data.data);

      message.success("Evaluation modules updated sucessfully");
      dispatch(actions.addEvaluationModuleSuccess());
    } catch (error) {
      const msg = error.response.data.message;
      message.error(msg);
    }
  };

export const deleteEvaluation = (id) => async (dispatch) => {
  try {
    const headers = dispatch(getHeaders());

    const route = `${URL}/api/evaluation/master/${id}`;

    const { data } = await axios.delete(route, { headers });

    console.log(data);
    message.success("Evaluation delete sucessfully");
    dispatch(actions.deleteEvaluationSuccess(id));
  } catch (error) {
    const msg = error.response.data.message;
    const errorMessage = msg ? msg : error.message;
    console.log(msg);
    message.error(errorMessage);
  }
};
