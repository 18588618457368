import { Col, Row } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Loading = ({ size }) => {
  return (
    <Row>
      <Col span={3} offset={10}>
        <Spin
          style={{
            marginTop: 10,
          }}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: size || 80,
              }}
              spin
            />
          }
        />
      </Col>
    </Row>
  );
};

export default Loading;
