import axios from "axios";
import { getHeaders } from "../auth/auth";
import { actions } from "../../actions/batch/batch.action";
import { message } from "antd";
import { redirect } from "react-router";

const URL = process.env.REACT_APP_API_URL;

export const getBatch = () => async (dispatch) => {
  try {
    dispatch(actions.getBatchRequest());

    const reqRoute = `${URL}/api/batch`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(reqRoute, {
      headers,
    });

    dispatch(actions.getBatchSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    message.error(msg);
    dispatch(actions.getBatchFailed(msg));
  }
};

export const addbatch = (batch_data) => async (dispatch) => {
  try {
    dispatch(actions.addBatchRequest());

    const reqRoute = `${URL}/api/batch`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.post(reqRoute, batch_data, {
      headers,
    });

    console.log(data.data);

    dispatch(actions.addBatchSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    message.error(msg);
    dispatch(actions.getBatchFailed(msg));
  }
};

export const deleteBatch = (id) => async (dispatch) => {
  try {
    const headers = dispatch(getHeaders());

    const route = `${URL}/api/batch/${id}`;

    const { data } = await axios.delete(route, { headers });

    console.log(data);
    message.success("Batch delete sucessfully");
    dispatch(actions.deleteBatchSuccess(id));
  } catch (error) {
    const msg = error.response.data.message;
    const errorMessage = msg ? msg : error.message;
    console.log(msg);
    message.error(errorMessage);
  }
};

export const generateFormat = (batch_title, isCourseType) => async (dispatch) => {
  try {
    dispatch(actions.generateFormatLoading());
    const headers = dispatch(getHeaders());

    let route =`${URL}/api/batch/download-excel-format`
    let response;
    
    if (isCourseType) {
      response = await axios.get(route, { params: {batch_title, course_type: isCourseType}, headers });
    }else{
      response = await axios.get(route, { params: {batch_title}, headers });
    }

    const { data } = response;

    console.log(data);
    message.success("Format generated sucessfully!");
    window.open(`${URL}/${data.data}`, "_blank");
    dispatch(actions.generateFormatSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    const errorMessage = msg ? msg : error.message;
    console.log(msg);
    message.error(errorMessage);
    dispatch(actions.generateFormatError(msg));
  }
};
export const generateCSV = (id, batchName) => async (dispatch) => {
  try {
    dispatch(actions.generateCSVLoading());
    const headers = dispatch(getHeaders());

    const data = await axios.get(`${URL}/api/batch/sendEmail/${id}`, { headers, responseType: "blob" });
    const url = window.URL.createObjectURL(data.data);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${batchName}.csv`;
    a.click();
    dispatch(actions.generateCSVSuccess(url));
    message.success("Format generated sucessfully!");
  } catch (error) {
    if(error.response.status === 400) return message.error('Student doenst exists in the batch');
    const msg = error.response.data.message;
    const errorMessage = msg ? msg : error.message;
    console.log(msg);
    message.error(errorMessage);
    dispatch(actions.generateCSVError(msg));
  }
};

export const downloadFileZip = (id) => async (dispatch) => {
  try {
    dispatch(actions.generateZIPLoading());
    const headers = dispatch(getHeaders());

    const route = `${URL}/api/batch/download-merge-pdf/${id}`;

    const { data } = await axios.get(route, { headers });

    console.log(data);
    setTimeout(function () {
      message.success("Zip generated sucessfully!");
      console.log(data.data, "data.data")
    }, 5000);
    window.open(`${URL}/${data.data}`, "_blank");
    // clearTimeout(time)
    dispatch(actions.generateZIPSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    const errorMessage = msg ? msg : error.message;
    console.log(msg);
    message.error(errorMessage);
    dispatch(actions.generateZIPError(msg));
  }
};

export const getOneBatch = (id) => async (dispatch) => {
  try {
    dispatch(actions.getOneBatchRequest());

    const route = `${URL}/api/batch/${id}`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(route, { headers });

    // console.log("school", data.data);

    dispatch(actions.getOneBatchSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    if (msg) dispatch(actions.getOneBatchError(msg));
  }
};

export const udpateBatch = (id, nData) => async (dispatch) => {
  try {
    dispatch(actions.updateBatchRequest());

    const headers = dispatch(getHeaders());

    const route = `${URL}/api/batch/${id}`;

    const { data } = await axios.put(route, nData, { headers });

    console.log(data);

    message.success("School updated sucessfully");
    dispatch(actions.updateBatchSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    if (msg) dispatch(actions.updateBatchError(msg));
  }
};

export const bluKMarksheetGen = (batch_id) => async (dispatch) => {
  try {
    dispatch(actions.bulkGenRequest());

    const headers = dispatch(getHeaders());

    const route = `${URL}/api/marksheet/batch-wise/generate/${batch_id}`;

    const { data } = await axios.get(route, { headers });

    console.log(data);

    message.success("Marksheets generated sucessfully", );
    dispatch(actions.bulkGenSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    if (msg) dispatch(actions.bulkGenFailed(msg));
  }
};
