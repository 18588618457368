import React, { useEffect } from "react";
import { LockFilled, LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Col, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../Redux/action-creators/auth/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data } = useSelector((state) => state.auth);

  useEffect(() => {
    if (data) navigate("/school");
  }, [data, navigate]);

  const onFinish = (values) => {
    dispatch(login(values));
    // if (data) navigate("/school");
    console.log("Received values of form: ", values);
  };
  return (
    <Row justify="center" style={{ padding: 130 }}>
      <Col span={6}>
        <div style={{ textAlign: "center", paddingBottom: 32 }}>
          <LockFilled style={{ fontSize: 60 }} color="primary" />
          <Typography.Title level="3" type="primary">
            Sign In
          </Typography.Title>
        </div>
        <Form
          name="normal_login"
          className="login-form"
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email Id"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
