/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { Link} from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="bg-white px-4 py-28 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex flex flex-row">
          <p className="text-8xl font-extrabold text-[#27AEE1] sm:text-8xl pr-6 font-sf-ui-display-bold">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6 border-gray-200 border-l pl-6">
              <h1 className="text-5xl mb-5 font-extrabold text-[#949494] sm:text-5xl font-sf-ui-display-bold tracking-wide">No Marksheet / Certificate Found</h1>
              <p className="text-base text-gray-500 font-sf-ui-display-medium">If you think this is an error then please contact CMT Team to help you with new Certificate / Marksheet.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6 pl-6">
            <Link to="/" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:text-white bg-[#27AEE1] hover:bg-[#1d82a7] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#27AEE1]">Go back home</Link>
            <Link to="#" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-[#27AEE1] bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#27AEE1]">Contact support</Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default PageNotFound;
