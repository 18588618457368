import React, { useEffect, useState } from "react";
import {
  BulbFilled,
  LogoutOutlined,
  CalculatorFilled,
  BuildFilled,
  CrownFilled,
  ContainerFilled,
  SettingFilled,
  MailOutlined,
  PlaySquareOutlined,
  FileDoneOutlined,
  FileMarkdownOutlined,
} from "@ant-design/icons";
import { Alert, Button, Layout, Menu, Tooltip, Typography } from "antd";
import "./SidebarLayout.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/action-creators/auth/auth";
import { GetBulkMarksheetState } from "../../Redux/redux_toolkit/bulkMarksheetState";

const { Header, Sider, Content } = Layout;

const SidebarLayout = ({ children, active = "school" }) => {
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { bulkState } = useSelector((state) => state.BulkMarksheet);

  const items = [
    {
      key: "school",
      icon: React.createElement(BulbFilled),
      label: "School",
      onClick: () => navigate("/school"),
    },
    {
      key: "batch",
      icon: React.createElement(BuildFilled),
      label: "Batch",
      onClick: () => navigate("/batch"),
    },
    {
      key: "evaluation",
      icon: React.createElement(CalculatorFilled),
      label: "Evaluation",
      onClick: () => navigate("/evaluation"),
    },
    {
      key: "student",
      icon: React.createElement(CrownFilled),
      label: "Students",
      onClick: () => navigate("/students"),
    },
    {
      key: "marksheet-outer",
      icon: React.createElement(PlaySquareOutlined),
      label: "Playground",
      default: "Playground",
      onClick: () => navigate("/playground-marksheet")
      // children: [
        // {
        //   key: "marksheet-inner",
        //   icon: React.createElement(ContainerFilled),
        //   label: "Marksheet",
        //   onClick: () => navigate("/marksheet"),
        // },
        // {
        //   key: "playgorund",
        //   icon: React.createElement(PlaySquareOutlined),
        //   label: "Playground",
        //   onClick: () => ,
        // },
      // ],
    },
    {
      key: "certificate",
      icon: React.createElement(FileDoneOutlined),
      label: "Certificate",
      onClick: () => navigate("/certificates"),
    },
    {
      key: "mail",
      icon: React.createElement(MailOutlined),
      label: "Mail",
      default: "send-mail",
      children: [
        {
          key: "send-marksheet",
          icon: React.createElement(FileMarkdownOutlined),
          label: "Send Marksheet",
          onClick: () => navigate("/send-marksheet-email"),
        },
        {
          key: "send-cert",
          icon: React.createElement(FileDoneOutlined),
          label: "Send Certificate",
          onClick: () => navigate("/send-certificate-email"),
        },
      ],
    },
    // {
    //   key: "setting",
    //   icon: React.createElement(SettingFilled),
    //   label: "Setting",
    //   children: [
    //     {
    //       key: "certificate",
    //       icon: React.createElement(ContainerFilled),
    //       label: "Certificate",
    //       onClick: () => navigate("/certificate-template"),
    //     },
    //   ]
    // }
  ];

  const handleLogout = () => {
    dispatch(logout());
    navigate("/admin");
  };

  return (
    <Layout hasSider>
      <Sider
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[active]}
          items={items}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            overflow: "initial",
            maxHeight: "100vh",
          }}
        >
          <Header
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography.Title
              level={4}
              style={{ color: "white", paddingTop: 8, paddingLeft: 180 }}
            >
              IIDE - Marksheet
            </Typography.Title>
            <Tooltip title="Logout" placement="bottom">
              <Button
                type="primary"
                style={{ marginLeft: "auto", display: 'flex', justifyContent: "center", alignItems: "center" }}
                onClick={() => handleLogout()}
                shape="round"
                size="middle"
                // icon={}
              >
                <LogoutOutlined/>
                Logout
              </Button>
            </Tooltip>
          </Header>
          <div
            style={{
              padding: 24,
              marginLeft: 200,
            }}
          >
            {bulkState && (
              <Alert
                message="Bulk Generate Done"
                type="success"
                closable
                afterClose={() => {
                  dispatch(GetBulkMarksheetState(false));
                }}
              />
            )}

            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;
