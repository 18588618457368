import React from "react";
import { Fragment, useEffect } from "react";
import Navbar from "../../Components/Student/Navbar";
import Footer from "./Footer";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { verifyStudentMarksheet } from "../../Redux/action-creators/marksheet/marksheet";
import moment from "moment";
import Loading from "../../Components/UI/Loading";
import { Helmet } from "react-helmet";
import Body from "./Body";
import axios from "axios";

const Verify = ({ socket }) => {
  const dispatch = useDispatch();

  const { loading, verify_marksheet } = useSelector(
    (state) => state.studentMarksheet
  );

  const { student_code } = useParams();

  useEffect(() => {
    dispatch(verifyStudentMarksheet(student_code));
  }, [student_code, dispatch]);

  let isCert = window.location.href.split("//")[1].split("/")[1];
  console.log("is Certificate ", isCert);

  const addAnalytic = (data) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/student/add-analytics`,
          {
            student_id: verify_marksheet.student_id,
            marksheet_id: verify_marksheet.id,
            ...data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => console.log(res.data))
        .catch((er) => console.log(er));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content="Verify page" />
        <meta property="og:image" content="//media.example.com/ 1234567.jpg" />
        <meta property="og:description" content="Verify page description" />

        {/* Primary Meta Tags */}
        <meta
          name="title"
          content={`${
            verify_marksheet && verify_marksheet.batch.program
          } | IIDE - The Digital School`}
        />
        <meta
          name="description"
          content={`${
            verify_marksheet && verify_marksheet.batch.program
          } | IIDE - The Digital School`}
        />

        {/* Open Graph / Facebook */}
        {/* <meta property="og:description" content={`${ verify_marksheet && verify_marksheet.batch.program } | IIDE.pdf`} /> */}

        {/* Twitter */}
        <meta
          property="twitter:card"
          content={`${
            verify_marksheet && verify_marksheet.batch.program
          } | IIDE - The Digital School`}
        />
        <meta
          property="twitter:url"
          content={
            verify_marksheet &&
            `${process.env.REACT_APP_API_URL}/${verify_marksheet.image}`
          }
        />
        <meta
          property="twitter:title"
          content={`${
            verify_marksheet && verify_marksheet.batch.program
          } | IIDE - The Digital School`}
        />
        {/* <meta property="twitter:description" content={`${ verify_marksheet && verify_marksheet.batch.program } | IIDE.pdf`}  /> */}
        <meta
          property="twitter:image"
          content={
            verify_marksheet &&
            `${process.env.REACT_APP_API_URL}/${verify_marksheet.image}`
          }
        />
      </Helmet>
      <div>
        <Navbar />
        {loading ? (
          <Fragment>
            <Loading />
          </Fragment>
        ) : (
          <>
            <Body
              verify_marksheet={verify_marksheet}
              addAnalytic={addAnalytic}
            />
            <Footer />
          </>
        )}
      </div>
    </Fragment>
  );
};

export default Verify;
