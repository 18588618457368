import { Button, Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormContainer from "../../../Components/UI/FormContainer";
import Loading from "../../../Components/UI/Loading";
import { addbatch } from "../../../Redux/action-creators/batch/batch";
import SidebarLayout from "../../Layout/SidebarLayout";
import { useLocation } from "react-router-dom";

const DuplicateComponent = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { state } = useLocation();

  let batch_data = state;

  if (batch_data){
    console.log("batch data", batch_data)
    batch_data.name = ""
  }

  const { loading, isInserted, add_batch_loading, update_success } =
    useSelector((state) => state.batch);

  let { data } = useSelector((state) => state.school);

  useEffect(() => {
    if (isInserted || update_success) navigate("/batch");
  }, [isInserted, navigate, update_success]);

  const handleFinish = (values) => {
    dispatch(addbatch(values));
  };

  //   console.log(school_data);
  return (
    <SidebarLayout active="batch">
      {loading ? (
        <Loading />
      ) : (
        <FormContainer>
          <Form
            layout={"vertical"}
            form={form}
            initialValues={batch_data && batch_data}
            autoComplete="off"
            onFinish={handleFinish}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter batch name!",
                },
              ]}
            >
              <Input placeholder="Enter batch name..." />
            </Form.Item>

            <Form.Item
              label="Program"
              name="program"
              rules={[
                {
                  required: true,
                  message: "Please enter program name!",
                },
              ]}
            >
              <Input placeholder="Enter program name..." />
            </Form.Item>

            <Form.Item
              label="Course Duration"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Please enter duration!",
                },
              ]}
            >
              <Input placeholder="Enter duration..." />
            </Form.Item>

            <Form.Item
              label="Batch Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select batch type",
                },
              ]}
            >
              <Select
                placeholder="Select batch type"
                options={[
                  {
                    label: "Under Graduate",
                    value: "UG",
                  },
                  {
                    label: "Post Graduate",
                    value: "PG",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="School"
              name="schoolId"
              rules={[
                {
                  required: true,
                  message: "Please select school",
                },
              ]}
            >
              <Select
                loading={loading}
                placeholder="Select school"
                options={
                  data &&
                  data.map((x) => {
                    return {
                      label: x.name,
                      value: x.id,
                    };
                  })
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={add_batch_loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </FormContainer>
      )}
    </SidebarLayout>
  );
};

export default DuplicateComponent;
