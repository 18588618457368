import { actions } from "../../actions/certificate/certificate";

const initialState = {
  data: [],
  loading: false,
  error: null,
  add_success: false,
};

const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_CERTIFICATE_BEGIN:
      return {
        ...state,
        loading: true,
        add_success: false,
      };
    case actions.CREATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        add_success: true,
      };
    case actions.CREATE_CERTIFICATE_FAILED:
      return {
        ...state,
        loading: false,
        add_success: false,
      };
    case actions.FETCH_CERTIFICATE_BEGIN:
      return {
        ...state,
        loading: true,
        add_success: false,
        update_success: false
      };
    case actions.FETCH_CERTIFICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.FETCH_CERTIFICATE_FAILED:
      return {
        ...state,
        loading: false,
      };

      case actions.FETCH_ONE_CERTIFICATE_BEGIN:
      return {
        ...state,
        get_one_loading: true,
        add_success: false,
        update_success: false
      };
    case actions.FETCH_ONE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        get_one_loading: false,
        one_cert_data: action.payload,
      };
    case actions.FETCH_ONE_CERTIFICATE_FAILED:
      return {
        ...state,
        get_one_loading: false,
      };

      case actions.UPDATE_CERTIFICATE_BEGIN:
        return {
          ...state,
          update_success: false
        };
      case actions.UPDATE_CERTIFICATE_SUCCESS:
        return {
          ...state,
          update_success: true
        };
      case actions.UPDATE_CERTIFICATE_FAILED:
        return {
          ...state,
          update_success: false
        };
    default:
      return state;
  }
};

export { certificateReducer };
