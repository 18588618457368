import React, { useEffect, useState } from "react";
import { notification } from "antd";
import SendMailModel from "../../Components/send_mail";
import SidebarLayout from "../Layout/SidebarLayout";

const CertMail = ({ socket }) => {
  const [batchDetail, setBatchDetail] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const [courseSelected, setCourseSelected] = useState("");
  const [isCourseType, setIsCourseType] = useState(false);
  const [batch, setBatch] = useState("");
  let [batchdata, setBatchdata] = useState([]);

  useEffect(() => {
    socket.emit("get_batch");
  }, []);

  useEffect(() => {
    socket.on("take_batch", (data) => {
      setBatchdata(data);
      setBatch(data[0].name);
      if (courseSelected !== "" && isCourseType){
        socket.emit("get-batch-detail",data[0].name, courseSelected);
      }else{
        socket.emit("get-batch-detail", data[0].name);
      }
    });

    socket.on("batch-details", (batch_details) => {
      console.log("batch data ", batch_details);
      setBatchDetail(batch_details);
    });

    socket.on("TemplateDoesNotExist", () => {
      api.info({
        message: `Template Error`,
        description: "Template Doesn't Exist",
        placement: "topRight",
      });
    });

    socket.on("mail-update", (batch_details) => {
      console.log("socket batch detail ", batch_details);
      // setBatchDetail(batch_details);
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [socket]);

  useEffect(()=> {
    socket.emit("get-batch-detail", batch, courseSelected);
  }, [batch, courseSelected])

  const send = (selectedRowKeys) => {
    socket.emit(
      "send-mail",
      selectedRowKeys,
      "certificate",
      window.location.origin
    );
  };

  return (
    <SidebarLayout active="send-cert">
      {contextHolder}
      <SendMailModel
        batchDetail={batchDetail}
        socket={socket}
        send={send}
        batch={batch}
        setBatch={setBatch}
        headtitle={"Certificate"}
        batchdata={batchdata}
        courseSelected={courseSelected}
        setCourseSelected={setCourseSelected}
        isCourseType={isCourseType}
        setIsCourseType={setIsCourseType}
      />
    </SidebarLayout>
  );
};

export default CertMail;
