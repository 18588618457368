import { Button, Modal, Form, Input, Select, DatePicker } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addbatch } from "../../Redux/action-creators/batch/batch";
import { batchType } from "../../utils/list";

const AddBatch = ({ open, handleCancel, handleOk }) => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.school);
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    console.log(values);
    dispatch(addbatch(values));
    form.resetFields();
    handleCancel();
  };

  return (
    <Modal
      title="Add Batch"
      open={open}
      confirmLoading={false}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        layout={"vertical"}
        form={form}
        initialValues={{
          layout: "vertical",
          remember: true,
        }}
        autoComplete="off"
        onFinish={handleFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter batch name!",
            },
          ]}
        >
          <Input placeholder="Enter batch name..." />
        </Form.Item>

        <Form.Item
          label="Program"
          name="program"
          rules={[
            {
              required: true,
              message: "Please enter program name!",
            },
          ]}
        >
          <Input placeholder="Enter program name..." />
        </Form.Item>

        <Form.Item
          label="Batch Type"
          name="type"
          rules={[
            {
              required: true,
              message: "Please select batch type",
            },
          ]}
        >
          <Select
            placeholder="Select batch type"
            options={batchType.map(val => val)}
          />
        </Form.Item>

        <Form.Item
          label="School"
          name="schoolId"
          rules={[
            {
              required: true,
              message: "Please select school",
            },
          ]}
        >
          <Select
            loading={loading}
            placeholder="Select school"
            options={
              data &&
              data.map((x) => {
                return {
                  label: x.name,
                  value: x.id,
                };
              })
            }
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddBatch;
