import React, { Fragment } from "react";
import moment from "moment";
import { ArrowDownTrayIcon, } from "@heroicons/react/24/solid";
import PageNotFound from "../PageNotFound";
import CertBody from "./CertBody";
import {
  XOutlined
} from "@ant-design/icons";
import CredentialSideBar from "./Components/CredentialSideBar";
import PgBatchSummary from "./Components/PgBatchSummary";
import AcdmBatchSummary from "./Components/AcdmBatchSummary";
import OldUgBatchSummary from "./Components/OldUgBatchSummary";

const Body = ({ verify_marksheet }) => {
  console.log(verify_marksheet);
  return (
    <Fragment>
      {!verify_marksheet ? (
        <PageNotFound />
      ) : (
        <Fragment>
          {verify_marksheet.batch.type === "PG" ? (
            <CertBody verify_marksheet={verify_marksheet} />
          ) : (
            <div
              className="flex flex-col mob:flex-col px-5 py-9 gap-y-9 mob:px-5 mob:py-9 mob:gap-y-9 pc:flex-row pc:h-[90vh] pc:px-[70px] "
              style={{ alignItems: "center" }}
            >
              <div className="basis-3/4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    className="rounded-md shadow-lg pc:h-[80vh]"
                    style={{
                      width: "auto",
                      boxShadow:
                        "0 3px 6px rgb(9 30 66 / 3%), 0 2px 4px rgb(9 30 66 / 8%)",
                    }}
                    src={
                      verify_marksheet && `${verify_marksheet.image}` // this is for marksheet image
                    }
                    alt="student_marksheet"
                  />
                </div>
              </div>
              <div
                className="pc:basis-1/4 w-full mob:w-full shadow-lg bg-[#039BE5] pc:h-[80vh] rounded-md"
                style={{
                  boxShadow:
                    "0 3px 6px rgb(9 30 66 / 3%), 0 2px 4px rgb(9 30 66 / 8%)",
                }}
              >
                <div className="flex flex-col text-center text-white rounded px-6 py-5 mob:py-5 pc:py-10">
                  <p className="text-lg mob:my-3 pc:my-5 font-medium font-sf-ui-display-medium">
                    This credential was issued to
                  </p>
                  <p className="mx-auto text-white text-2xl font-extrabold mb-0 font-sf-ui-display-bold">
                    {verify_marksheet && verify_marksheet.student.name}
                  </p>
                  <p className="text-lg font-medium mob:my-3 pc:my-5 font-sf-ui-display-bold">
                    {verify_marksheet &&
                      moment(verify_marksheet.updatedAt).format("ll")}
                  </p>
                  <a
                    type="button"
                    href={verify_marksheet && verify_marksheet?.linkedInUrl}
                    target={"_blank"}
                    className="flex flex-row my-3 justify-evenly bg-white mx-auto w-60 rounded-md py-1 border-2 border-white hover:bg-white group transition-all duration-75"
                  >
                    <div className="text-lg text-[#039BE5] font-sf-ui-display-bold">
                      Add to{" "}
                      <span className="text-[#0e76a8] font-sf-ui-display-bold">
                        Linked
                      </span>
                      <i
                        className="fa-brands fa-linkedin text-[#0e76a8]"
                        style={{ marginLeft: "1px", lineHeight: "0px" }}
                      ></i>
                    </div>
                  </a>
                  <a
                    href={
                      verify_marksheet && `${verify_marksheet.pdf}` // this is for marksheet pdf
                    }
                    className="flex flex-row justify-items-start bg-[#039BE5] mx-auto w-60 rounded-md mt-3 py-1 border-2 border-white hover:bg-white group transition-all duration-100"
                    target="_blank"
                    download="marksheet"
                  >
                    <ArrowDownTrayIcon className="h-5 w-5 mt-1 text-white group-hover:text-[#039BE5] ml-5" />
                    <div className="text-lg text-white font-sf-ui-display-bold group-hover:text-[#039BE5] ml-3">
                      Download PDF
                    </div>
                  </a>
                  <h1 className="mx-auto text-3xl font-extrabold text-white mt-5 mb-5 font-sf-ui-display-bold">
                    Share
                  </h1>
                  <div className="flex flex-row justify-evenly pb-6">
                    <a
                      className="rounded-md bg-white pt-1"
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href
                        }&title=${verify_marksheet && verify_marksheet.batch.program
                        } | IIDE - The Digital School`}
                      target={"_blank"}
                      rel="nofollow"
                    >
                      <i
                        className="fa-brands fa-linkedin-in text-[#039be5] mx-1 p-1"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </a>
                    <a
                      className="rounded-md bg-white pt-1"
                      href={`https://twitter.com/share?url=Hey!%0A%0AI'm%20happy%20to%20share%20with%20you%20that%20I've%20received%2Fsecured%20Grade%20${verify_marksheet && verify_marksheet.grade
                        }%20in%20@iideonline's%20 ${verify_marksheet && verify_marksheet.batch.program
                        }.%20%0A%0AThis%20has%20taught%20me%20to%20be%20diligent%20and%20go%20for%20what%20I%20want.%20Super%20grateful%20to%20see%20my%20results.%20%0A%0ACheck%20this%20out!%20${window.location.href
                        }`}
                      target={"_blank"}
                      rel="nofollow"
                    >
                      <XOutlined className="mx-1 p-1 !text-[#039be5]" style={{ fontSize: "20px" }} />
                    </a>
                    <a
                      className="rounded-md bg-white"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}%2F&amp;src=sdkpreparse`}
                      target={"_blank"}
                      rel="nofollow"
                    >
                      <i
                        className="fa-brands fa-facebook-f mx-1 px-1 text-[#039be5]"
                        style={{ fontSize: "20px", padding: "6px 6px 1px 6px" }}
                      ></i>
                    </a>
                    <a
                      className="rounded-md bg-white"
                      href={`https://api.whatsapp.com/send?text=Hey!%0A%0AI'm%20happy%20to%20share%20with%20you%20that%20I've%20received%2Fsecured%20Grade%20${verify_marksheet && verify_marksheet.grade
                        }%20in%20IIDE's%20 ${verify_marksheet && verify_marksheet.batch.program
                        }.%20%0A%0AThis%20has%20taught%20me%20to%20be%20diligent%20and%20go%20for%20what%20I%20want.%20Super%20grateful%20to%20see%20my%20results.%20%0A%0ACheck%20this%20out!%20${window.location.href
                        }`}
                      target={"_blank"}
                      rel="nofollow"
                    >
                      <i
                        className="fa-brands fa-whatsapp mx-1 p-1 text-[#039be5]"
                        style={{ fontSize: "20px", paddingTop: "6px" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}

          <section className="flex flex-col mob:flex-col-reverse tab:flex-col-reverse pc:flex-row mob:px-0 mob:pb-5 pc:px-[70px] pc:pt-[70px] pc:pb-0 mob:gap-7 tab:gap-6 pc:gap-12 gap-0">


            <CredentialSideBar
              verify_marksheet={verify_marksheet}
            />

            {
              (
               verify_marksheet.batch.type && verify_marksheet.batch.type === 'PG') ? (
                <PgBatchSummary verify_marksheet={verify_marksheet} />
              )
                : (
                  ( verify_marksheet.student.type && verify_marksheet.student.type === 'ACDM') ? (
                    <AcdmBatchSummary verify_marksheet={verify_marksheet} />
                  ) :
                    (
                      ( verify_marksheet.student.type && verify_marksheet.student.type === 'UG_OLD') && <OldUgBatchSummary 
                      verify_marksheet={verify_marksheet}
                      />
                    )


                )
            }



          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Body;
