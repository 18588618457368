const actions = {
  GET_STUDENT_REQUEST: "GET_STUDENT_REQUEST",
  GET_STUDENT_SUCCESS: "GET_STUDENT_SUCCESS",

  ADD_STUDENT_REQUEST: "ADD_STUDENT_REQUEST",
  UPDATE_STUDENT_REQUEST: "UPDATE_STUDENT_REQUEST",
  ADD_STUDENT_SUCCESS: "ADD_STUDENT_SUCCESS",

  DELETE_STUDENT_SUCESS: "DELETE_STUDENT_SUCESS",

  deleteStudentSuccess: (id) => {
    return {
      type: actions.DELETE_STUDENT_SUCESS,
      payload: id
    }
  },

  getStudentRequest: () => {
    return {
      type: actions.GET_STUDENT_REQUEST,
    };
  },

  getStudentSuccess: (payload) => {
    return {
      type: actions.GET_STUDENT_SUCCESS,
      payload,
    };
  },

  addStudentRequest: () => {
    return {
      type: actions.ADD_STUDENT_REQUEST,
    };
  },
  updateStudentRequest: () => {
    return {
      type: actions.UPDATE_STUDENT_REQUEST,
    };
  },

  addStudentSuccess: (payload) => {
    return {
      type: actions.ADD_STUDENT_SUCCESS,
      payload,
    };
  },
};

export { actions };
