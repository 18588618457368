import axios from "axios";
import { getHeaders } from "../../action-creators/auth/auth";
import { actions } from "../../actions/marksheet/marksheet.action";
import { message } from "antd";

const URL = process.env.REACT_APP_API_URL;

export const getStudentMarksheets = () => async (dispatch) => {
  try {
    dispatch(actions.getStudentMarksheetRequest());

    const route = `${URL}/api/marksheet/student`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(route, { headers });

    dispatch(actions.getStudentMarksheetSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
  }
};

export const searchStudentMarksheet = (student_code) => async (dispatch) => {
  try {
    const { status, data } = await axios.get(`${URL}/api/marksheet/student/search-doc/${student_code}`);
    if(status === 200) {
      actions.marksheetSearchSuccess(data.data)
      return window.open(`${window.location.origin}/${data.data[0].student.student_code}`, "_self")
    }
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    dispatch(actions.marksheetSearchFailed(msg));
  }
};

export const searchStudentMarksheetReset = () => async (dispatch) => {
  dispatch(actions.marksheetSearchReset());
};

export const addStudentMarksheet = (nData) => async (dispatch) => {
  try {
    // dispatch(actions.addStu());

    const headers = dispatch(getHeaders());

    const route = `${URL}/api/marksheet/student/create`;

    const { data } = await axios.post(route, nData, { headers });

    console.log(data);

    message.success(data.message);
    dispatch(actions.addStudentMarksheetSuccess(data.data));
  } catch (error) {
    console.log("get marksheet error  ",error)
    const msg = error.response.data.message;
    console.log(error);
    message.error(msg);
  }
};

export const getStudentMarksheetsWithModules =
  (marksheet_id) => async (dispatch) => {
    try {
      dispatch(actions.getStudentMarksheetModulesRequest());

      const route = `${URL}/api/marksheet/student/${marksheet_id}/details`;

      const headers = dispatch(getHeaders());

      const { data } = await axios.get(route, { headers });

      dispatch(actions.getStudentMarksheetModulesSuccess(data.data));
    } catch (error) {
      const msg = error.response.data.message;
      console.log(msg);
      message.error(msg);
    }
  };

export const updateModuleObtainedMarks = (udata, id) => async (dispatch) => {
  console.log("udata from marksheet ", udata)
  try {
    dispatch(actions.updateModuleObtainedMarksRequest());

    const route = `${URL}/api/marksheet/module/obtained-marks/${id}`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.put(route, udata, { headers });

    message.success(data.message);
    dispatch(actions.updateModuleObtainedMarksSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
  }
};

export const genMarksheet = (id) => async (dispatch) => {
  try {
    dispatch(actions.genMarksheetRequest());

    const route = `${URL}/api/pdf/${id}`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.get(route, { headers });

    message.success(data.message);
    dispatch(actions.genMarksheetSuccess());
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
  }
};

export const calcFinalMarks = (id) => async (dispatch) => {
  try {
    dispatch(actions.calcFinalMarksRequest());

    const route = `${URL}/api/marksheet/${id}/marks-calculate`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.put(route, { headers });

    message.success(data.message);
    dispatch(actions.calcFinalMarksSuccess());
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
  }
};

export const updateAttendance = (id, udata) => async (dispatch) => {
  try {
    dispatch(actions.updateAttendanceRequest());

    const route = `${URL}/api/marksheet/${id}`;

    const headers = dispatch(getHeaders());

    const { data } = await axios.put(route, udata, { headers });

    message.success(data.message);
    dispatch(actions.updateAttendanceSuccess());
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
  }
};

export const deleteStudentMarksheet = (id) => async (dispatch) => {
  try {
    const headers = dispatch(getHeaders());

    const route = `${URL}/api/marksheet/${id}`;

    const { data } = await axios.delete(route, { headers });

    console.log(data);
    message.success("Student's Marksheet delete sucessfully");
    dispatch(actions.deleteMarksheetSuccess(id));
  } catch (error) {
    
    const errorMessage = "Unexpected error";
    console.log(error);
    message.error(errorMessage);
  }
};

export const verifyStudentMarksheet =
  (student_code) => async (dispatch) => {
    try {
      dispatch(actions.verifyStudentRequest());

      const route = `${URL}/api/marksheet/student/verify/${student_code}`;


      const { data } = await axios.get(route);

      dispatch(actions.verifyStudentSuccess(data.data));
    } catch (error) {
      const msg = error.response.data.message;
      console.log(msg);
      // message.error(msg);
      if (msg) dispatch(actions.verifyStudentFailed(msg));
    }
  };
