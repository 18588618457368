import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { getHeaders } from "../action-creators/auth/auth";

const URL = process.env.REACT_APP_API_URL;

let initialState = {
  item: [],
  error: "",
};

const GetMarksheetScreen = createSlice({
  name: "GetMarkSheet",
  initialState,
  reducers: {
    GetMarksheetSuccess(state, action) {
      state.item = action.payload;
      state.error = "";
    },
    GetMarksheetFailed(state, action) {
      state.error = action.payload;
      state.item = [];
    },
  },
});

export const GetMarksheets = (batch_name, course_type) => async (dispatch) => {
  try {
    console.log("for student")
    const route = `${URL}/api/marksheet/marksheets`;

    const headers = dispatch(getHeaders());
    
    if (course_type) {
        console.log("batch name ", batch_name, course_type);
      const { data } = await axios.get(route, {
        headers,
        params: { batch_name, course_type },
      });
      console.log("get marksheet error  ", data);
      dispatch(GetMarksheetSuccess(data.data));
    } else {
      const { data } = await axios.get(route, {
        headers,
        params: { batch_name },
      });
      console.log("get marksheet error batch  ", data);
      dispatch(GetMarksheetSuccess(data.data));
    }
  } catch (error) {
    const msg = error.response.data.message;
    console.log("get marksheet error ", msg);
    message.error(msg);
  }
};

const { GetMarksheetSuccess, GetMarksheetFailed } = GetMarksheetScreen.actions;
export default GetMarksheetScreen.reducer;
