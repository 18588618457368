const courses = [
  {
    label: "Advance Certification in Digital Marketing (ACDM)",
    value: "ACDM",
  },
  {
    label: "Professional Certification in Digital Marketing Strategies (PCDMS)",
    value: "PCDMS",
  },
  {
    label: "UG (old)",
    value: "UG_OLD",
  },
  {
    label: "PG",
    value: "PG",
  },
  {
    label: "KG",
    value: "KG",
  },
];

const batchType = [
  {
    label: "Under Graduate",
    value: "UG",
  },
  {
    label: "Post Graduate",
    value: "PG",
  },
  {
    label: "KG Micro College",
    value: "KG",
  },
]

export {courses, batchType}
