import axios from "axios";
import actions from "../../actions/auth/auth.action";

import { message } from 'antd'

const {
  loginBegin,
  loginErr,
  loginSuccess,
  logoutErr,
  logoutBegin,
  logoutSuccess
} = actions;

const URL = process.env.REACT_APP_API_URL;

export const login = (login_data) => async (dispatch) => {
  try {
    dispatch(loginBegin());

    const route = `${URL}/api/auth/login`;

    const { data } = await axios.post(route, login_data);
    localStorage.setItem("user_info", JSON.stringify(data.data));

    message.success(`Welcome, ${data.data.name}!`);
    dispatch(loginSuccess(data.data));
  } catch (error) {
    const msg = error.response.data.message;
    console.log(msg);
    message.error(msg);
    if (msg) dispatch(loginErr(msg));
  }
};

export const logout = () => (dispatch) => {
  try {
    dispatch(logoutBegin());

    localStorage.removeItem("user_info");

    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logoutErr(error.message));
  }
};

export const getHeaders = () => (dispatch, getState) => {
  const {
    auth: { data },
  } = getState();
  return {
    Authorization: `Bearer ${data.token}`,
  };
};
