import action from "../../actions/auth/auth.action";

const {
  LOGIN_BEGIN,
  LOGIN_ERR,
  LOGIN_SUCCESS,
  LOGOUT_BEGIN,
  LOGOUT_ERR,
  LOGOUT_SUCCESS,
} = action;

// getting user info from localstorage
const userInfo = localStorage.getItem("user_info");

const initialState = {
  data: userInfo ? JSON.parse(userInfo) : null,
  loading: false,
  error: null,
};

const AuthReducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data,
      };

    case LOGIN_ERR:
      return {
        ...state,
        loading: false,
        error,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        loading: false,
        data: null,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export { AuthReducer };
