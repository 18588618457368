const actions = {
  ADD_SCHOOL_SUCCESS: "ADD_SCHOOL_SUCCESS",
  ADD_SCHOOL_REQUEST: "ADD_SCHOOL_REQUEST",
  ADD_SCHOOL_FAILED: "ADD_SCHOOL_FAILED",
  GET_SCHOOL_REQUEST: "GET_SCHOOL_REQUEST",
  GET_SCHOOL_SUCCESS: "GET_SCHOOL_SUCCESS",
  GET_SCHOOL_FAILED: "GET_SCHOOL_FAILED",
  DELETE_SCHOOL_FAILED: "GET_SCHOOL_FAILED",
  DELETE_SCHOOL_SUCCESS: "DELETE_SCHOOL_SUCCESS",
  RESET_ADD_SCHOOL_SUCCESS: "RESET_ADD_SCHOOL_SUCCESS",

  GET_ONE_SCHOOL_REQUEST: "GET_ONE_SCHOOL_REQUEST",
  GET_ONE_SCHOOL_SUCCESS: "GET_ONE_SCHOOL_SUCCESS",
  GET_ONE_SCHOOL_FAILED: "GET_ONE_SCHOOL_FAILED",

  UPDATE_SCHOOL_SUCCESS: "UPDATE_SCHOOL_SUCCESS",
  UPDATE_SCHOOL_REQUEST: "UPDATE_SCHOOL_REQUEST",
  UPDATE_SCHOOL_FAILED: "UPDATE_SCHOOL_FAILED",

  updateSchoolRequest: () => {
    return {
      type: actions.UPDATE_SCHOOL_REQUEST,
    };
  },

  updateSchoolSuccess: (payload) => {
    return {
      type: actions.UPDATE_SCHOOL_SUCCESS,
      payload,
    };
  },

  updateSchoolError: (err) => {
    return {
      type: actions.UPDATE_SCHOOL_FAILED,
      error: err,
    };
  },

  getOneSchoolRequest: () => {
    return {
      type: actions.GET_ONE_SCHOOL_REQUEST,
    };
  },

  getOneSchoolSuccess: (payload) => {
    return {
      type: actions.GET_ONE_SCHOOL_SUCCESS,
      payload,
    };
  },

  getOneSchoolError: (err) => {
    return {
      type: actions.GET_ONE_SCHOOL_FAILED,
      error: err,
    };
  },

  deleteSchoolSuccess: (id) => {
    console.log(id, "from delete school success");
    return {
      type: actions.DELETE_SCHOOL_SUCCESS,
      deletedId: id,
    };
  },

  addSchool: () => {
    return {
      type: actions.ADD_SCHOOL_SUCCESS,
    };
  },

  addSchoolRequest: () => {
    return {
      type: actions.ADD_SCHOOL_REQUEST,
    };
  },

  addSchoolError: (err) => {
    return {
      type: actions.ADD_SCHOOL_FAILED,
      error: err,
    };
  },

  getSchoolRequest: () => {
    return {
      type: actions.GET_SCHOOL_REQUEST,
      loading: true,
    };
  },

  getSchoolSuccess: (data) => {
    return {
      type: actions.GET_SCHOOL_SUCCESS,
      data,
    };
  },

  getSchoolError: (err) => {
    return {
      type: actions.GET_SCHOOL_FAILED,
      error: err,
    };
  },

  resetAddSchool: () => {
    return {
      type: actions.RESET_ADD_SCHOOL_SUCCESS,
    };
  },
};

export default actions;
