import { Col, Row } from "antd";
import React from "react";
import classes from "./FormContainer.module.css";

const FormContainer = ({ children }) => {
  return (
    <Row>
      <Col span={8} offset={7} className={classes["form-container"]}>
        {children}
      </Col>
    </Row>
  );
};

export default FormContainer;
