import React, { useEffect, useRef, useState } from "react";
import {
  SearchOutlined,
  DeleteFilled,
  EyeFilled,
  ExclamationCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Menu,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  Dropdown,
} from "antd";
import Highlighter from "react-highlight-words";
import SidebarLayout from "../Layout/SidebarLayout";
import Notification from "../../Components/notification";
import SendMailModel from "../../Components/send_mail";
import { io } from "socket.io-client";
import HeadTitle from "../../Components/title";

// const URL = process.env.REACT_APP_API_URL;

const ListCert = ({ socket }) => {
  // let socket = io(URL)
  const [batch, setBatch] = useState("");

  let [batchdata, setBatchdata] = useState([]);
  // let { item } = useSelector(state => state.AllCert);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  let [showProgress, setShowProgress] = useState(false);
  const [courseSelected, setCourseSelected] = useState("");
  const [isCourseType, setIsCourseType] = useState(false);

  let [progressCount, setProgressCount] = useState(0);
  let [outOf, setOutOf] = useState(0);
  let [item, setItem] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const start = () => {
    socket.emit("generate_certificate", selectedRowKeys);
    setShowProgress(true);
  };

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    socket.emit("get_batch");
  }, []);

  useEffect(() => {
    console.log("use effect of socket");

    socket.on("connected", () => {
      socket.emit("get_batch");
    });

    socket.on("take_batch", (data) => {
      setBatchdata(data);
      console.log("take batch data ", data)
      setBatch(data[0].name);
    });

    socket.on("response_to_get", (data) => {
      setItem(data);
      console.log("data Value ", data);
    });

    socket.on("disable_show_after", () => {
      console.log("disable progress has started running");
      setProgressCount(0);
      setOutOf(0);
      setShowProgress(false);
    });

    socket.on("certificate_process", (data) => {
      console.log("data progress count ", data);
      setShowProgress(true);
      setProgressCount(data.progress);
      setOutOf(data.outOfProgress);
      if (courseSelected !== "" && isCourseType) {
        socket.emit("get_certificate", batch, courseSelected);
      }else {
        socket.emit("get_certificate", batch);
      }
    });

    socket.on("done_all_certificate", () => {
      // socket.emit("get_certificate", batch)
      setSelectedRowKeys([]);
      socket.emit("send_show_progress_state_time");
    });

    socket.on("cert_deleted_successfully", () => {
      console.log("deleted certificate successfully ");
      if (courseSelected !== "" && isCourseType) {
        socket.emit("get_certificate", batch, courseSelected);
      }else {
        socket.emit("get_certificate", batch);
      }
    });

    socket.on("failed_deleting_certificate", (id) => {
      Notification(`Certificate Deleted ${id}`, "Failed Deleting");
    });

    // socket.on("take_verify_link", (data) => {
    //     window.open(data, "_blank");
    // })
    return () => {
      socket.removeAllListeners();
    };
  }, [socket, item]);

  useEffect(() => {
    if (courseSelected !== "" && isCourseType) {
      socket.emit("get_certificate", batch, courseSelected);
    }else {
      socket.emit("get_certificate", batch);
    }
  }, [batch, isCourseType, courseSelected]);

  let isAnyCertNotCreated = false;

  if (item) {
    item.map((fdata) => {
      if (!fdata.isCertCreated) {
        isAnyCertNotCreated = true;
      }
    });
  }

  // const batchMenu = (
  //   <Menu>
  //     {batchdata.map((data) => (
  //       <Menu.Item
  //         key={data.id}
  //         onClick={() => {
  //           setBatch(data.name);
  //           // socket.emit("get_certificate", batch)
  //         }}
  //       >
  //         {data.name}
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  const columns = [
    {
      title: "Student Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Student Code",
      dataIndex: "student_code",
      key: "student_code",
      ...getColumnSearchProps("student_code"),
    },
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      key: "batch_name",
      ...getColumnSearchProps("batch_name"),
    },
    {
      title: "Batch Type",
      dataIndex: "batch_type",
      key: "batch_type",
      ...getColumnSearchProps("batch_type"),
    },
    {
      title: "Student Type",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  if (item && item.length) {
    item = item.map((x) => {
      return {
        key: x.id,
        ...x,
        name: x.student ? x.student.name : "N/A",
        student_code: x.student ? x.student.student_code : "N/A",
        batch_name: x.batch ? x.batch.name : "N/A",
        batch_type: x.batch ? x.batch.type : "N/A",
        type: x.student ? x.student.type : "N/A",
        action: !x.isCertCreated ? null : (
          <div className="table-actions">
            <Tooltip title="Download Certificate" placement="top">
              <a href={x.certificatePdf}>
                <DownloadOutlined
                  style={{
                    color: "green",
                    cursor: "pointer",
                    marginRight: 10,
                    fontSize: 18,
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="View Certificate" placement="top">
              <a href={`certificate/${x.student.student_code}`} target="_blank">
                <EyeFilled
                  title="View"
                  className="mr-3"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    marginRight: 10,
                    fontSize: 15,
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <DeleteFilled
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: 10,
                  fontSize: 18,
                }}
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure you want to delete this Certificate?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk() {
                      socket.emit("deleted_certificate", x.id);
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    });
  }

  const rowSelection = {
    getCheckboxProps: (record) => {
      // item.map(fdata => {
      if (record.isCertCreated) {
        return {
          disabled: true,
        };
      }
      // })
      console.log(
        "console log og record get check box props ",
        record.isCertCreated
      );
    },
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <SidebarLayout active="certificate">
      {/* {contextHolder} */}
      <Table
        columns={columns}
        dataSource={item}
        // loading={loading}
        rowSelection={isAnyCertNotCreated ? { ...rowSelection } : null}
        title={() => (
          <div className="title-div">
            <HeadTitle
              title={"Certificate"}
              setBatch={setBatch}
              batch_data={batchdata}
              batch={batch}
              courseSelected={courseSelected}
              setCourseSelected={setCourseSelected}
              isCourseType={isCourseType}
              setIsCourseType={setIsCourseType}
            />
            <Button
              type="primary"
              onClick={() => start()}
              disabled={!hasSelected}
              style={{ marginLeft: "auto" }}
            >
              Generate Certificate
            </Button>
          </div>
        )}
        pagination={{
          defaultPageSize: 100,
          position: ["bottomCenter"],
          total: item ? item.length : 0,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </SidebarLayout>
  );
};

export default ListCert;
