import React from 'react'
import moment from "moment";

const OldUgBatchSummary = ({verify_marksheet}) => {
    let below_fifty = "< 50";

    return (
        <div className="w-full p-5 px-5 pb-0 py-0 mob:p-0 tab:px-5 tab:pb-5 tab:py-0 m-auto pc:m-0 mob:w-full tab:m-auto pc:basis-3/4 ">
              <div>
                <h1 className="mob:text-xl pc:text-3xl font-bold mb-0 text-[#222] font-sf-ui-display-bold">
                  {verify_marksheet && verify_marksheet.batch.program}
                </h1>
              </div>
              <div className="flex flex-wrap mob:flex-col tab:flex-col pc:flex-row">
                <div className="mob:basis-1/3 basis-1/3 pc:basis-1/3 mt-5">
                  <div className="text-lg font-sf-ui-display-bold">
                    Issued on
                  </div>
                  <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                    {verify_marksheet &&
                      moment(verify_marksheet.batch.convocation_date).format("ll")
                    }
                  </div>
                </div>
                <div className="mob:basis-1/3 basis-1/3 pc:basis-1/3 mt-5">
                  <div className="text-lg font-sf-ui-display-bold">
                    Batch Title
                  </div>
                  <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                    {verify_marksheet && verify_marksheet.batch.name}
                  </div>
                </div>
                <div className="mob:basis-1/3 basis-1/3 pc:basis-1/3 mt-5">
                  <div className="text-lg font-sf-ui-display-bold">
                    Duration
                  </div>
                  <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                    {verify_marksheet && verify_marksheet.batch.duration}
                  </div>
                </div>
              </div>


              <div className="pt-5 mob:pt-9 tab:pt-9 pc:pt-5">
                <h1 className="mob:text-xl pc:text-xl font-bold mb-0 text-[#222] font-sf-ui-display-bold">
                  GRADING SYSTEM:
                </h1>
                <ul className="list-disc pt-2 font-sf-ui-display-medium text-sm mob:ml-3 ml-9">
                  <li className="mb-1">
                  IIDE follows the Absolute Grading System while calculating the final grade.
                  </li>
                  <li className="mb-1">
                  Absolute Grading is a standardised evaluation of varying levels of achievement. A letter grade is assigned to fixed percentage scales. Percentage scores are derived as follows:
                    <ul
                      style={{
                        marginLeft: "25px",
                        paddingTop: "5px",
                        listStyle: "outside",
                      }}
                    >
                      <li className="mb-1">
                      The final modular and Capstone Project scores have two criteria: the assignment and exam scores. While calculating the final score, 70% weightage is given to the assignment score and 30% weightage to the exam score. The formula then is = (Assignment Score*70/100) + (Exam Score*30/100).
                      </li>
                      <li className="mb-0">
                      For the overall score, the modular score and the Capstone Project score are given equal weightage. Therefore, the formula is = 50% of Modular Scores + 50% of the Capstone Project Score.
                      </li>
                    </ul>
                  </li>
                </ul>


                <h1 className="mob:text-xl pc:text-xl font-bold mb-0 text-[#222] font-sf-ui-display-bold">
                  NOTES:
                </h1>
                <ul className="list-disc pt-2 font-sf-ui-display-medium text-sm mob:ml-3 ml-9 mb-7">
                  <li className="mb-1">
                  These grades are for IIDE’s Under graduation, a 4 month Instructor-Led, Online-Live program.
                  </li>
                  <li className="mb-1">
                  Performance Marketing includes Organic & Paid Search Marketing & Analytics. Creative Strategy includes Content Marketing & Strategy & Social Media Marketing.
                  </li>
                  <li className="mb-1">
                  The Capstone Project is a comprehensive test and serves as a full-course assessment.
                  </li>
                  <li className="mb-1">
                  Grades are calculated based on predetermined cutoff levels and standards of performance as decided by IIDE and thus, should not be compared to any other institute.
                  </li>
                </ul>
                <table
                  className="text-center w-full overflow-hidden rounded-md"
                  style={{
                    boxShadow:
                      "0 3px 6px rgb(9 30 66 / 3%), 0 2px 4px rgb(9 30 66 / 8%)",
                  }}
                >
                      <tr className="bg-[#039be5] font-sf-ui-display-bold text-xl text-white">
                        <td className="border-r-2" style={{ width: "12%" }}>
                          A
                        </td>
                        <td className="border-r-2" style={{ width: "12%" }}>
                          B
                        </td>
                        <td className="border-r-2" style={{ width: "12%" }}>
                          C
                        </td>
                        <td className="border-r-2" style={{ width: "12%" }}>
                          D
                        </td>
                        <td style={{ width: "12%" }}>F</td>
                      </tr>
                      <tr className="font-sf-ui-display-medium bg-[#F7F9FF] mob:text-l pc:text-xl">
                        <td className="border-r-1" style={{ width: "12%" }}>
                          85 - 100%
                        </td>
                        <td className="border-r-1" style={{ width: "12%" }}>
                          70 - 84%
                        </td>
                        <td className="border-r-1" style={{ width: "12%" }}>
                          69 - 60%
                        </td>
                        <td className="border-r-1" style={{ width: "12%" }}>
                          50-59%
                        </td>
                        <td className="border-r-1" style={{ width: "12%" }}>
                          {'< 50'}
                        </td>
                      </tr>
                   
                </table>
              </div>
            </div>
    )
}

export default OldUgBatchSummary;