import { Button, ConfigProvider, DatePicker, Form, Input, Select,Switch } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormContainer from "../../Components/UI/FormContainer";
import Loading from "../../Components/UI/Loading";
import {
  addbatch,
  getOneBatch,
  udpateBatch,
} from "../../Redux/action-creators/batch/batch";
import SidebarLayout from "../Layout/SidebarLayout";

const BatchForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  let { loading, isInserted, add_batch_loading, update_success, batch_data } =
    useSelector((state) => state.batch);

  let { data } = useSelector((state) => state.school);

  useEffect(() => {
    if (isInserted || update_success) navigate("/batch");
  }, [isInserted, navigate, update_success]);

  useEffect(() => {
    if (id) {
      dispatch(getOneBatch(id));
    }
    // let clearTime = setTimeout(() => {
    //   console.log("batch data convocation date before ", batch_data);
    //   batch_data = {
    //     ...batch_data,
    //     convocation_date:
    //       batch_data ? moment(batch_data.convocation_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : undefined,
    //   };
    //   console.log("batch data convocation date ", batch_data);
    //   setConvocation(batch_data.convocation_date);
    // }, 1000);

    // return () => {
    //   clearTimeout(clearTime);
    // };
  }, [id, dispatch]);

  console.log("selectedConvocation", batch_data)
  const [selectedConvocation, setSelectedConvocation] = useState(
    batch_data && batch_data.convocation_date
      ? moment(batch_data.convocation_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
      // : moment().format('YYYY-MM-DD')
      : undefined
  );


  const handleFinish = (values) => {
    if (values.convocation_date === undefined) {
      values.convocation_date = moment();
    }
    if (!id) {
      dispatch(addbatch(values));
    } else if (id) {
      dispatch(udpateBatch(id, values));
    }
  };

  return (
    <SidebarLayout active="batch">
      {loading ? (
        <Loading />
      ) : (
        <FormContainer>
          <Form
            layout={"vertical"}
            form={form}

            initialValues={
              batch_data && batch_data.convocation_date === null ?
                {
                  ...batch_data,
                  convocation_date: undefined
                  // convocation_date: undefined,
                  //   batch_data ? moment(batch_data.convocation_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : undefined,

                } : {
                  ...batch_data
                }
            }
            autoComplete="off"
            loading={loading}
            onFinish={handleFinish}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter batch name!",
                },
              ]}
            >
              <Input placeholder="Enter batch name..." />
            </Form.Item>

            <Form.Item
              label="Program"
              name="program"
              rules={[
                {
                  required: true,
                  message: "Please enter program name!",
                },
              ]}
            >
              <Input placeholder="Enter program name..." />
            </Form.Item>

            <Form.Item
              label="Convocation Date"
              name="convocation_date"
              // defaultValue={moment()}
              // onReset={moment()}
              // initialValue={convocation === null && moment(Date.now()).toDate()}
              required={true}
              // initialValue={null}
              getValueFromEvent={(onChange) => moment(onChange)}
              getValueProps={(i) => ({ value: moment(i) })}

            //value={(batch_data && batch_data.convocation_date ) ? batch_data.convocation_date : moment() }
            >
              <DatePicker
                style={{ width: "100%" }}
                clearIcon={false}
                // name="convocation_date"
                value={selectedConvocation}
                onChange={(date) => setSelectedConvocation(date)}
                getPopupContainer={(trigger) => trigger.parentNode}
                // placeholder="Select date" 
                renderExtraFooter={() => {
                  if (batch_data && !batch_data.convocation_date) {
                    return <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>Select date</span>;
                  }
                  return null;
                }}
              // defaultValue={batch_data ? moment(batch_data.convocation_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : null} 
              />
            </Form.Item>

            <Form.Item
              label="Course Duration"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Please enter duration!",
                },
              ]}
            >
              <Input placeholder="Enter duration..." />
            </Form.Item>

            <Form.Item
              label="Batch Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select batch type",
                },
              ]}
            >
              <Select
                placeholder="Select batch type"
                options={[
                  {
                    label: "Under Graduate",
                    value: "UG",
                  },
                  {
                    label: "Post Graduate",
                    value: "PG",
                  },
                  {
                    label: "KG MICRO College",
                    value: "KG",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="School"
              name="schoolId"
              rules={[
                {
                  required: true,
                  message: "Please select school",
                },
              ]}
            >
              <Select
                loading={loading}
                placeholder="Select school"
                options={
                  data &&
                  data.map((x) => {
                    return {
                      label: x.name,
                      value: x.id,
                    };
                  })
                }
              />
            </Form.Item>
<Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={add_batch_loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </FormContainer>
      )}
    </SidebarLayout>
  );
};

export default BatchForm;
