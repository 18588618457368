import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space, message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createEvaluationModule,
  queryEvaluationModules,
  updateEvaluationModule,
} from "../../Redux/action-creators/evaluation/evaluation";
import SidebarLayout from "../Layout/SidebarLayout";
import Loading from "../../Components/UI/Loading";

const AddEvaluationModules = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const naviate = useNavigate();

  const { id } = params;

  const { loading, query_data } = useSelector((state) => state.evaluation);

  const prepareReqdata = (data) => {
    const finalData = [];

    data.forEach((ele, index) => {
      for (let i = 0; i < 2; i++) {
        const maxMarks =
          i === 0
            ? Number(ele.assignment_weightage) * 100
            : Number(ele.exam_weightage) * 100;
        const weightage =
          i === 0 ? ele.assignment_weightage : ele.exam_weightage;
        finalData.push({
          order: Number(index),
          name: ele.name,
          code: ele.name.replace(" ", "").toLowerCase(),
          type: ele.type,
          module_type: i === 0 ? "ASSIGNMENT" : "EXAM",
          maxMarks,
          weightage,
        });
      }
    });

    return finalData;
  };

  useEffect(() => {
    if (id && !query_data) {
      dispatch(queryEvaluationModules({ evaluation_master_id: Number(id) }));
    }
  }, [id, dispatch, query_data]);
  console.log(query_data, "query_data");

  const onFinish = (values) => {
    const reqBody = prepareReqdata(values.evaluation);
    if (query_data) {
      dispatch(updateEvaluationModule(params.id, reqBody));
    } else {
      dispatch(createEvaluationModule(params.id, reqBody));
    }
    if (!loading) {
      naviate("/evaluation");
    }
    console.log("Received values of form:", values);
  };

  let options = [
    {
      label: "Module",
      value: "MODULE",
    },
    {
      label: "Final Project",
      value: "FINAL_PROJECT",
    },
  ];

  return (
    <SidebarLayout active="evaluation">
      {!query_data ? (
        <Loading />
      ) : (
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.List
            name="evaluation"
            initialValue={query_data && query_data.length ? query_data : []}
          >
            {(fields, { add, remove }) => {
              console.log(fields);
              return (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "Missing name",
                          },
                          {
                            pattern: /^[ a-zA-Z0-9\!\(\)\?,_*@./#&+-]{1,45}$/,
                            message: "Name Must be in Between 45 Character",
                          },
                        ]}
                      >
                        <Input placeholder="Enter name of module" />
                      </Form.Item>

                      {/* <Form.Item
                        {...restField}
                        name={[name, "code"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing code",
                          },
                        ]}
                      >
                        <Input placeholder="Enter code of module" />
                      </Form.Item> */}

                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        label="Type"
                        initialValue={options[0].value}
                        rules={[
                          {
                            required: true,
                            message: "Type is missing",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: 130 }}
                          placeholder="Select type"
                          options={options}
                          defaultValue={options[0]}
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "assignment_weightage"]}
                        label="Assignment Weightage"
                        initialValue={"0.7"}
                        rules={[
                          {
                            required: true,
                            message: "Missing weightage",
                          },
                          {
                            pattern: /^[0-9]\.\d$/,
                            message: "Value should contain just Decimal",
                          },
                        ]}
                      >
                        <Input placeholder="Assignment Weightage" />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "exam_weightage"]}
                        label="Exam Weightage"
                        initialValue={"0.3"}
                        rules={[
                          {
                            required: true,
                            message: "Missing weightage",
                          },
                          {
                            pattern: /^[0-9]\.\d$/,
                            message: "Value should contain just Decimal",
                          },
                        ]}
                      >
                        <Input placeholder="Exam Weightage" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      <div 
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 10
                        }}
                      >
                        <PlusOutlined style={{
                          fontSize: 15,
                          fontFamily: "sf-ui-display-medium",
                          marginTop: 3
                        }} /> 
                        {/* <h3>+</h3> */}
                        Add Module
                      </div>
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </SidebarLayout>
  );
};
export default AddEvaluationModules;
