import { Button, Modal, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createEvaluationMaster, duplicateEvaluationMaster } from "../../Redux/action-creators/evaluation/evaluation";
import {
  addNewSchool,
  getSchool,
} from "../../Redux/action-creators/school/school";
import {courses} from "../../utils/list"

const AddEvaluation = ({ open, handleCancel, handleOk, duplicate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { insertLoading } = useSelector((state) => state.evaluation);
  const { loading, data } = useSelector((state) => state.batch);
  const [courseType, setCourseType] = useState([])
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    if (duplicate) {
      dispatch(duplicateEvaluationMaster(values, duplicate))
    } else {
      dispatch(createEvaluationMaster(values));
    }
    form.resetFields();
    handleCancel();
    // dispatch(getSchool());
    // navigate("/school");
  };
  console.log("data ", data)

  return (
    <Modal
      title={duplicate ? "Duplicate Evaluation" : "Add Evaluation"}
      open={open}
      confirmLoading={false}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        layout={"vertical"}
        form={form}
        initialValues={{
          layout: "vertical",
          remember: true,
        }}
        autoComplete="off"
        onFinish={handleFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[           
            {
              required: true,
              message: "Please enter evaluation name!",
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder="Enter evaluation name..."/>
        </Form.Item>

        <Form.Item
          label="Batch"
          name="batch_id"
          rules={[
            {
              required: true,
              message: "Please select batch",
            },
          ]}
        >
          <Select
            loading={loading}
            placeholder="Select batch"
            options={
              data &&
              data.map((x) => {
                return {
                  label: x.name,
                  value: x.id,
                };
              })
            }
            // onChange={(e) => {
            //   data &&
            //   data.map((x) => {
            //     if (e === x.id && x.type === "UG") {
            //       setCourseType(courses.filter(course => course.value !== "PG"))
            //     }else if (e === x.id && x.type === "PG"){
            //       setCourseType(courses.filter(course => course.value === "PG"))
            //     }
            //   })
            // }}
          />
        </Form.Item>
        <Form.Item
          label="Course Evaluation Type"
          name="type"
          rules={[
            {
              required: true,
              message: "Please select student Course type",
            }
          ]}
        >
          <Select
            placeholder="Course Evaluation type"
            options={courses.map(val=> val)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={insertLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEvaluation;
