import React from "react";
import {
    XOutlined
  } from "@ant-design/icons";

const CredentialSideBar = ({ verify_marksheet }) => {
    // console.log("Verify marskheet ", verify_marksheet);
  let url;
  if (
    verify_marksheet &&
    verify_marksheet.type.typeOfUrl === "marksheet" &&
    verify_marksheet.type.typeOfBatch === "UG"
  ) {
    url =
      "https://iide.co/online-digital-marketing-course/?utm_source=ug_marksheet&utm_medium=share_page&utm_campaign=iide&utm_term=ug_alumini&utm_content=iide_website_button&utm_team=website_team";
  } else if (
    verify_marksheet && verify_marksheet.type.typeOfUrl === "marksheet" &&
    verify_marksheet.type.typeOfBatch === "PG"
  ) {
    console.log("");
    url =
      "https://iide.co/master-mba-digital-marketing/?utm_source=pg_marksheet&utm_medium=share_page&utm_campaign=iide&utm_term=pg_alumini&utm_content=iide_website_button&utm_team=website_team";
  }
    return (
        <div className="w-full p-5 px-5 pb-5 py-0 mob:p-0 tab:px-5 tab:pb-5 tab:py-0 pc:px-0 m-auto pc:m-0 mob:w-full tab:m-auto pc:basis-1/4">
              <div
                className="flex flex-col text-center py-9 mob:py-2 mob:px-3 pc:py-9 bg-[#f7f9ff] rounded-md"
                style={{
                  boxShadow:
                    "0 3px 6px rgb(9 30 66 / 3%), 0 2px 4px rgb(9 30 66 / 8%)",
                }}
              >
                <h1 className="text-center font-medium text-lg mt-5 mb-0 font-sf-ui-display-medium">
                  This credential has been issued by
                </h1>
                <div className="w-36 h-36 m-auto my-10 mob:my-5 pc:my-10 bg-white shadow-lg shadow-gray-200 rounded-md">
                  <img
                    className="mob:w-20 mob:mx-auto mob:mt-11 w-16 h-12 mt-11 ml-10 pc:w-16 pc:h-12 pc:mt-11 pc:ml-10"
                    src="https://res.cloudinary.com/ddizpfhtk/image/upload/v1668682125/img/IIDE-Logo_xmqq0z.png"
                  />
                </div>
                <div className="font-extrabold text-center text-2xl font-sf-ui-display-medium mob:mt-5">
                  IIDE - The Digital School
                </div>
                <div className="text-center pt-5">
                  <a
                    className="bg-[#039BE5] px-10 mob:px-6 tab:px-10 pc:px-14 py-3 rounded-md text-white text-lg font-sf-ui-display-medium hover:text-white"
                    href={`${url}`}
                    rel="dofollow"
                    target={"_blank"}
                  >
                    IIDE's website
                  </a>
                </div>
                <div className="pt-5 text-center text-black underline font-bold font-sf-ui-display-medium">
                  <a
                    href="mailto:support@iide.co"
                    rel="dofollow"
                    className="text-black"
                  >
                    Contact IIDE
                  </a>
                </div>
                <div className="flex flex-row justify-evenly mob:py-8 pc:py-10 text-[#222]">
                  <a
                    rel="dofollow"
                    className="rounded-md bg-[#039be5] text-white pt-1 hover:text-white"
                    href="https://www.linkedin.com/school/indian-institution-of-digital-education/"
                    target={"_blank"}
                  >
                    <i
                      className="fa-brands fa-linkedin-in mx-1 p-1" x
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                  <a
                    rel="dofollow"
                    className="rounded-md bg-[#039be5] pt-1 hover:text-white"
                    target={"_blank"}
                    href="https://twitter.com/IIDEonline"
                  >
                    <XOutlined className="mx-1 p-1 !text-[#fefefe]" style={{ fontSize: "20px" }} />
                  </a>
                  <a
                    rel="dofollow"
                    className="rounded-md bg-[#039be5] hover:text-white"
                    target={"_blank"}
                    href="https://www.facebook.com/IndianInstituteOfDigitalEducation/"
                  >
                    <i
                      className="fa-brands fa-facebook-f px-3 py-2 text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                  <a
                    rel="dofollow"
                    className="rounded-md bg-[#039be5] pt-1 hover:text-white"
                    href="https://www.instagram.com/iideonline"
                    target={"_blank"}
                  >
                    <i
                      className="fa-brands fa-instagram mx-1 p-1 text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
    )
}

export default CredentialSideBar;