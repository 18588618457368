import React from "react";
import moment from "moment";

const PgBatchSummary = ({ verify_marksheet }) => {

    return(
        <div className="w-full p-5 px-5 pb-0 py-0 mob:p-0 tab:px-5 tab:pb-5 tab:py-0 m-auto pc:m-0 mob:w-full tab:m-auto pc:basis-3/4 ">
              <div>
                <h1 className="mob:text-xl pc:text-3xl font-bold mb-0 text-[#222] font-sf-ui-display-bold">
                  {verify_marksheet && verify_marksheet.batch.program}
                </h1>
              </div>
              <div className="flex flex-wrap mob:flex-col tab:flex-col pc:flex-row">
                <div className="mob:basis-1/3 basis-1/3 pc:basis-1/3 mt-5">
                  <div className="text-lg font-sf-ui-display-bold">
                    Issued on
                  </div>
                  <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                    {verify_marksheet &&
                      moment(verify_marksheet.batch.convocation_date).format("ll")
                    }
                  </div>
                </div>
                <div className="mob:basis-1/3 basis-1/3 pc:basis-1/3 mt-5">
                  <div className="text-lg font-sf-ui-display-bold">
                    Batch Title
                  </div>
                  <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                    {verify_marksheet && verify_marksheet.batch.name}
                  </div>
                </div>
                <div className="mob:basis-1/3 basis-1/3 pc:basis-1/3 mt-5">
                  <div className="text-lg font-sf-ui-display-bold">
                    Duration
                  </div>
                  <div className="font-medium text-base mt-1 font-sf-ui-display-medium">
                    {verify_marksheet && verify_marksheet.batch.duration}
                  </div>
                </div>
              </div>

              <div className="border border-black rounded-lg text-sm font-medium mob:w-full w-10/12 mt-5 mob:mt-9 tab:mt-9 pc:mt-5">
                <div className="flex border-b border-black font-bold">
                  <div className="border-r border rounded-tl-[7px] border-black p-2 mob:pl-2 pc:pl-7 break-words w-2/6 bg-[#bbeaf9] text-base">Course</div>
                  <div className="p-2 rounded-tr-[7px] border border-black mob:pl-2 pc:pl-7 break-words w-4/6 text-base">Module</div>
                </div>
                <div className="flex">
                  <div className="border-r border-b border-black p-2 mob:pl-2 pc:pl-7 break-words w-2/6 flex items-center bg-[#bbeaf9]">Foundation</div>
                  <div className="w-4/6">
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Introduction to Digital Marketing</div>
                  </div>
                </div>
                <div className="flex">
                  <div className="border-r border-b border-black p-2 mob:pl-2 pc:pl-7 break-words w-2/6 flex items-center bg-[#bbeaf9]">Search Marketing</div>
                  <div className="w-4/6">
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Website Planning and Development</div>
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Search Engine Optimization</div>
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Search Engine Marketing</div>
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">App Store Optimization</div>
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Ecommerce Management</div>
                  </div>
                </div>
                <div className="flex">
                  <div className="border-r border-b border-black p-2 mob:pl-2 pc:pl-7 break-words w-2/6 flex items-center bg-[#bbeaf9]">Social Marketing</div>
                  <div className="w-4/6">
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Social Media Organic + Social Media Paid</div>
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Online Reputation Management</div>
                  </div>
                </div>
                <div className="flex">
                  <div className="border-r border-b border-black p-2 mob:pl-2 pc:pl-7 break-words w-2/6 flex items-center bg-[#bbeaf9]">Planning & Strategy</div>
                  <div className="w-4/6">
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Google Analytics</div>
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Brand Strategy</div>
                    <div className="p-2 mob:pl-2 pc:pl-7 break-words border-b border-black">Media Planning</div>
                  </div>
                </div>
                <div className="flex border-b border-black">
                  <div className="border-r border-black p-2 mob:pl-2 pc:pl-7 break-words w-2/6 bg-[#bbeaf9]">Business Skills</div>
                  <div className="p-2 mob:pl-2 pc:pl-7 break-words w-4/6">Blogging Adsense & Affiliate Marketing</div>
                </div>
                <div className="flex border-b border-black">
                  <div className="border-r border-black p-2 mob:pl-2 pc:pl-7 break-words w-2/6 bg-[#bbeaf9]">Performance Marketing</div>
                  <div className="p-2 mob:pl-2 pc:pl-7 break-words w-4/6">Conversion Rate Optimization</div>
                </div>
                <div className="flex">
                  <div className="border-r border-black p-2 mob:pl-2 pc:pl-7 break-words w-2/6 bg-[#bbeaf9] rounded-bl-lg">Creative Marketing</div>
                  <div className="p-2 mob:pl-2 pc:pl-7 break-words w-4/6">Video Marketing and Script Writing</div>
                </div>

              </div>


              <div className="pt-5 mob:pt-9 tab:pt-9 pc:pt-5">
                <h1 className="mob:text-xl pc:text-xl font-bold mb-0 text-[#222] font-sf-ui-display-bold">
                  GRADING SYSTEM:
                </h1>
                <ul className="list-disc pt-2 font-sf-ui-display-medium text-sm mob:ml-3 ml-9">
                  <li className="mb-1">
                    IIDE employs the Absolute Grading System, using fixed percentage scales
                    corresponding to letter grades.
                  </li>
                  <li className="mb-1">
                    Final scores for Modular Assessments and the Capstone Project are determined by a
                    70% weightage on assignment scores and a 30% weightage on exam scores.
                  </li>
                  <li className="mb-1">
                    The overall score equally combines Modular Scores and Capstone Project Score for
                    which the formula is = 50% of Modular Scores + 50% of Capstone Project Score.
                  </li>
                </ul>


                <h1 className="mob:text-xl pc:text-xl font-bold mb-0 text-[#222] font-sf-ui-display-bold">
                  IMPORTANT:
                </h1>
                <ul className="list-disc pt-2 font-sf-ui-display-medium text-sm mob:ml-3 ml-9 mb-7">
                  <li className="mb-1">
                    Changes to this Statement of Marks are not allowed without authorization. Tampering
                    with this document will result in invalidation and possible penalties by the Institute.
                  </li>
                  <li className="mb-1">
                    Grades are determined by IIDE's academic norms & should not be compared to other
                    Institutes.
                  </li>
                </ul>
                <table
                  className="text-center w-full overflow-hidden rounded-md"
                  style={{
                    boxShadow:
                      "0 3px 6px rgb(9 30 66 / 3%), 0 2px 4px rgb(9 30 66 / 8%)",
                  }}
                >
                      <tr className="bg-[#039be5] font-sf-ui-display-bold text-xl text-white">
                        <td className="border-r-2" style={{ width: "12%" }}>
                          A
                        </td>
                        <td className="border-r-2" style={{ width: "12%" }}>
                          B
                        </td>
                        <td className="border-r-2" style={{ width: "12%" }}>
                          C
                        </td>
                        <td className="border-r-2" style={{ width: "12%" }}>
                          D
                        </td>
                        <td style={{ width: "12%" }}>F</td>
                      </tr>
                      <tr className="font-sf-ui-display-medium bg-[#F7F9FF] mob:text-l pc:text-xl">
                        <td className="border-r-1" style={{ width: "12%" }}>
                          85 - 100%
                        </td>
                        <td className="border-r-1" style={{ width: "12%" }}>
                          70 - 84%
                        </td>
                        <td className="border-r-1" style={{ width: "12%" }}>
                          69 - 60%
                        </td>
                        <td className="border-r-1" style={{ width: "12%" }}>
                          50-59%
                        </td>
                        <td className="border-r-1" style={{ width: "12%" }}>
                          {'< 35%'}
                        </td>
                      </tr>
                   
                </table>
              </div>
            </div>
    )

}
 export default PgBatchSummary;